import { gql, useMutation } from "@apollo/client";

export const PHONE_VALIDATION = gql(`
mutation ValidatePhone($phone: String!) {
  validatePhone(phone: $phone) {
    isValid
  }
}`);

export const EMAIL_VALIDATION = gql(`
mutation ValidateEmail($email: String!) {
  validateEmail(email: $email) {
    isValid
  }
}`);

export function useValidateMutation() {
  const [validatePhone] = useMutation(PHONE_VALIDATION);

  const [validateEmail] = useMutation(EMAIL_VALIDATION);

  return { validateEmail, validatePhone };
}
