import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./Apollo/Auth";
import store from "./Store/index";
import ThemeProvider from "./Theme/index";
import ScrollToTop from "./Utils/Components/ScrollToTop";
import LandingPage from "./LandingPage";

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <AuthProvider>
          <BrowserRouter>
            <ScrollToTop />
            <LandingPage />
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
