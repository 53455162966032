import { Box, Rating, Typography, useTheme } from "@mui/material";
import React from "react";

const DRating = (props: any) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        component="legend"
        variant="body2"
        color={theme.palette.grey[600]}
      >
        {props.label} {props.required && "*"}
      </Typography>
      <Rating max={props.max} />
    </Box>
  );
};

export default DRating;
