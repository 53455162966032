import shortid from "shortid";

export const SIDEBAR_ITEM = "item";
export const ROW = "row";
export const COLUMN = "column";
export const COMPONENT = "component";

export const FIELDS = {
  TEXT_FIELD: "textField",
  RADIO_FIELD: "radioGroup",
  CHECK_FIELD: "checkGroup",
  DROP_DOWN_FIELD: "dropDown",
  DATE_PICKER_FIELD: "datePicker",
  IMAGE_FIELD: "image",
  IMAGE_UPLOD_FIELD: "imageUpload",
  TEXT_AREA_FIELD: "textArea",
  TYPOGRAPHY_FIELD: "typography",
  RATING_FIELD: "rating",
  ALERT_FIELD: "alert",
  CARD_FIELD: "card",
  GRID_FIELD: "grid",
};

export const SIDEBAR_ITEMS = [
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.TYPOGRAPHY_FIELD,
      label: "Typography",
      icon: "uim:paragraph",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.ALERT_FIELD,
      label: "Alert",
      icon: "line-md:alert-twotone",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.TEXT_FIELD,
      label: "Text Field",
      icon: "ph:textbox-duotone",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.RADIO_FIELD,
      label: "Radio Group",
      icon: "ph:radio-button-duotone",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.CHECK_FIELD,
      label: "Checkbox Group",
      icon: "ic:twotone-library-add-check",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.DROP_DOWN_FIELD,
      label: "Drop Down",
      icon: "mdi:form-dropdown",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.RATING_FIELD,
      label: "Rating",
      icon: "ic:twotone-star",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.DATE_PICKER_FIELD,
      label: "Date Picker",
      icon: "ic:twotone-date-range",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.IMAGE_FIELD,
      label: "Image",
      icon: "ic:twotone-image",
    },
  },
  {
    id: shortid.generate(),
    type: SIDEBAR_ITEM,
    component: {
      type: FIELDS.IMAGE_UPLOD_FIELD,
      label: "Image Upload",
      icon: "majesticons:image-plus-line",
    },
  },

  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: FIELDS.IMAGE_UPLOD_FIELD,
  //     label: "Image Upload",
  //     icon: "ic:twotone-image",
  //   },
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: FIELDS.TEXT_AREA_FIELD,
  //     label: "Text Area",
  //     icon: "ic:twotone-text-snippet",
  //   },
  // },
  // {
  //   id: shortid.generate(),
  //   type: SIDEBAR_ITEM,
  //   component: {
  //     type: FIELDS.CARD_FIELD,
  //     label: "Card",
  //     icon: "ant-design:credit-card-twotone",
  //   },
  // },
];
