import { Alert, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";

const AlertBar = ({
  open,
  message,
  handleClose,
}: {
  open: boolean;
  message: string;
  handleClose: () => void;
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        variant="filled"
        severity="error"
        onClose={handleClose}
        sx={{ width: "100%" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertBar;
