import { Delete, Edit } from "@mui/icons-material";
import { Box, Card, Grid, IconButton } from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import Column from "./Column";
import { ROW } from "./Const";
import DropZone from "./DropZone";
import Options from "./Options";

const Row = ({ data, components, handleDrop, path, onDelete, onCopy }: any) => {
  const ref = useRef(null);

  const [{}, drag] = useDrag(() => ({
    type: ROW,
    item: {
      id: data.id,
      type: ROW,
      children: data.children,
      path,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  drag(ref);

  const [isHover, setisHover] = useState(false);

  const [isChildHovered, setisChildHovered] = useState(false);

  const isHovered = useCallback(
    (hovered: boolean) => {
      if (hovered) {
        setisHover(!hovered);
      }
      setisChildHovered(hovered);
    },
    [isHover]
  );

  const renderColumn = (column: any, currentPath: any) => {
    return (
      <Column
        key={column.id}
        data={column}
        components={components}
        handleDrop={handleDrop}
        path={currentPath}
        onDelete={onDelete}
        onCopy={onCopy}
        isHovered={isHovered}
      />
    );
  };

  return (
    <Grid
      container
      ref={ref}
      onMouseOver={!isChildHovered ? () => setisHover(true) : undefined}
      onMouseOut={!isChildHovered ? () => setisHover(false) : undefined}
      sx={{
        border: 1,
        borderStyle: "dotted",
        position: "relative",
        borderRadius: 0.4,
        paddingY: 2,
      }}
    >
      {data.children.map((column: any, index: any) => {
        const currentPath = `${path}-${index}`;

        return (
          <Grid item key={column.id} display={"flex"} {...column}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
              isHorizontal={true}
            />

            {renderColumn(column, currentPath)}
            <DropZone
              data={{
                path: `${path}-${index + 1}`,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
              isHorizontal={true}
            />
          </Grid>
        );
      })}

      {data.children.length === 0 && (
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
          }}
          onDrop={handleDrop}
          isHorizontal={true}
          isLast={true}
        />
      )}
      <Options
        isHover={isHover}
        isEdit={false}
        onCopy={() =>
          onCopy({
            id: data.id,
            type: ROW,
            children: data.children,
            path,
          })
        }
        onDelete={() =>
          onDelete({
            id: data.id,
            type: ROW,
            children: data.children,
            path,
          })
        }
      />
    </Grid>
  );
};

export default Row;
