import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  useTheme,
  FormHelperText,
} from "@mui/material";
import React from "react";

const DRadioGroup = (props: any) => {
  const theme = useTheme();

  return (
    <FormControl>
      <FormLabel
        sx={{
          typography: "body2",
          color: `${theme.palette.grey[600]}!important`,
        }}
      >
        {props.label} {props.required && "*"}
      </FormLabel>
      <RadioGroup value={props.value} row={props.isRow}>
        {props.options.map((option: any, index: any) => (
          <FormControlLabel
            key={index}
            value={option}
            control={
              <Radio
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 16,
                  },
                }}
              />
            }
            label={option}
            componentsProps={{ typography: { variant: "body2" } }}
          />
        ))}
      </RadioGroup>
      <FormHelperText>{""}</FormHelperText>
    </FormControl>
  );
};

export default DRadioGroup;
