import { styled } from "@mui/material/styles";
import { Card, Container, Typography } from "@mui/material";
import React from "react";
import useResponsive from "../../Hooks/UseResponsive";
import Logo from "../../Components/Logo";
import audit from "../../Assets/audit.webp";
import { LOGIN } from "../../Utils/Const";
import LoginForm from "./LoginForm";

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Login() {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  return (
    <RootStyle>
      <HeaderStyle>
        <Logo open={true} />
      </HeaderStyle>

      {mdUp && (
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            {LOGIN.WELCOME}
          </Typography>
          <img src={audit} alt="login" />
        </SectionStyle>
      )}

      <Container maxWidth="sm">
        <ContentStyle>
          <Typography variant="h4" gutterBottom>
            {LOGIN.SIGNIN}
          </Typography>

          <Typography sx={{ color: "text.secondary", mb: 5 }}>
            {LOGIN.CREDENTIALS}
          </Typography>

          {/* <AuthSocial /> */}

          <LoginForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
