import { createSlice } from "@reduxjs/toolkit";

interface DrawerOption {
  isOpen: boolean;
  item: any;
}

const initialState: DrawerOption = {
  isOpen: false,
  item: {},
};

export const drawerSlice = createSlice({
  name: "drawer",
  initialState,
  reducers: {
    drawerReset: (state) => {
      state.isOpen = false;
    },
    onOpen: (state, action) => {
      state.isOpen = action.payload.isOpen;
      state.item = action.payload.item;
    },
  },
});

export const { drawerReset, onOpen } = drawerSlice.actions;

export default drawerSlice.reducer;
