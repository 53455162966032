import { gql, useMutation } from "@apollo/client";
import { ALL_USERS } from "./AllUsers";

const DELETE_USER = gql`
  mutation DeleteUser($userId: String!) {
    deleteUser(userId: $userId) {
      id
    }
  }
`;

export function useDeleteUserMutation() {
  const [deletUser, { data, error, loading, reset }] = useMutation(
    DELETE_USER,
    {
      update(cache, { data }) {
        const deletedId = data?.deleteUser.id;
        const existingUsers: any = cache.readQuery({
          query: ALL_USERS,
        });

        if (deletedId && existingUsers) {
          cache.writeQuery({
            query: ALL_USERS,
            data: {
              users: existingUsers?.users.filter(
                (x: { id: any }) => x.id !== deletedId
              ),
            },
          });
        }
      },
    }
  );
  return { deletUser, data, error, loading, reset };
}
