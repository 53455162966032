import { gql, useQuery } from "@apollo/client";

export const ALL_ROLES = gql`
  query Roles {
    roles {
      id
      name
      isActive
      createdAt
      updatedAt
      screens {
        name
        isCreate
        isUpdate
        isDelete
        isView
      }
      count
    }
  }
`;

export function useRolesQuery() {
  const { data, error, loading } = useQuery(ALL_ROLES);
  const { roles } = data || { roles: [] };

  return { roles, error, loading };
}
