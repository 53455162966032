import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useUsersQuery } from "../../Api/Users/AllUsers";
import { useDeleteUserMutation } from "../../Api/Users/DeleteUser";
import BreadCrums from "../../Components/Breadcrum";
import DailogBox from "../../Components/ConfirmDailog";
import Iconify from "../../Components/Iconify";
import Loader from "../../Components/Loader";
import UseMoreMenu from "../../Components/MoreButton";
import NoData from "../../Components/NoData";
import Scrollbar from "../../Components/Scrollbar";
import AlertBar from "../../Components/SnackBar";
import TableHeader from "../../Components/TableHeader";
import useRole from "../../Hooks/UseRole";
import { useAppDispatch } from "../../Store/Hooks";
import { isloading, User } from "../../Store/Slices/UserSlice";
import { ENABLED, USERS } from "../../Utils/Const";
import { UserForm } from "./UserForm";
import UserListToolbar from "./UserSearch";

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 50,
    width: "100%",
    backgroundColor: theme.palette.primary.main,
  },
}));

const TABLE_HEAD = [
  { id: "firstName", label: "Name" },
  { id: "phone", label: "Phone" },
  { id: "email", label: "Email" },
  { id: "role", label: "Role" },
  { id: "isActive", label: "Status" },
  { id: "" },
];

const userIntialValue: User = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  photo: null,
  role: null,
  isActive: true,
};

const Users = () => {
  const dispatch = useAppDispatch();

  const [status, setstatus] = useState(0);

  const { users, loading } = useUsersQuery();

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("firstName");

  const [role, setrole] = useState("ALL");

  const [name, setname] = useState("");

  const [page, setPage] = useState(0);

  const [selected, setselected] = useState<User | null>(null);

  const [openForm, setopenForm] = useState(false);

  const [showError, setshowError] = useState(false);

  const [openDeleteDailog, setopenDeleteDailog] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [isEdit, setisEdit] = useState(false);

  const { deletUser } = useDeleteUserMutation();

  const onRoleChange = (value: string) => setrole(value);

  const onFilterName = (e: any) => setname(e.target.value);

  const { myRole } = useRole("Users");

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const onDeleteDailogOpen = (user: User) => {
    setselected(user);
    setopenDeleteDailog(true);
  };

  const onDeleteDailogClose = () => {
    setselected(null);
    setopenDeleteDailog(false);
  };

  const onDeleteRole = async () => {
    const id = selected?.id;
    onDeleteDailogClose();
    dispatch(isloading(true));
    await deletUser({
      variables: {
        userId: id,
      },
    })
      .catch((err) => setshowError(true))
      .finally(() => dispatch(isloading(false)));
  };

  const onUserEditFormOpen = (user: User) => {
    setselected(user);
    setisEdit(true);
    setopenForm(true);
  };

  const onUserFormClose = () => {
    setopenForm(false);
    if (selected !== null && isEdit) {
      setselected(null);
      setisEdit(false);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setstatus(newValue);
  };

  const filteredUsers = users
    ? users.filter(
        (x: any) =>
          (status === 2 ? true : status === 1 ? !x.isActive : x.isActive) &&
          (role === "ALL" ? true : x.role.name === role) &&
          (name !== ""
            ? `${x.firstName}  ${x.lastName}`
                .toLowerCase()
                .indexOf(name.toLowerCase()) !== -1
            : true)
      )
    : [];

  return (
    <Container>
      <UserForm
        open={openForm}
        user={isEdit ? selected : userIntialValue}
        isEdit={isEdit}
        handleClose={onUserFormClose}
      />
      <DailogBox
        showDialog={openDeleteDailog}
        cancelNavigation={onDeleteDailogClose}
        confirmNavigation={onDeleteRole}
        message={USERS.CONFIRM_DELETE}
      />
      <AlertBar
        open={showError}
        handleClose={() => setshowError(false)}
        message={USERS.DELETE_FAILED}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4">Users</Typography>
        {myRole?.isCreate && (
          <Button
            variant="contained"
            onClick={() => setopenForm(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {USERS.NEW_USER}
          </Button>
        )}
      </Stack>
      <BreadCrums links={[{ name: "Users", href: "" }]} />
      <Card sx={{ my: 2, border: 1, borderColor: "divider" }}>
        <Box
          sx={{
            borderBottom: 1,
            px: 2,
            borderColor: "divider",
            bgcolor: "background.neutral",
          }}
        >
          <StyledTabs value={status} onChange={handleChange}>
            <Tab disableRipple label={ENABLED.ACTIVE} />
            <Tab disableRipple label={ENABLED.DISABLED} />
            <Tab disableRipple label="All" />
          </StyledTabs>
        </Box>
        <UserListToolbar
          selectedRole={role}
          onRoleChange={onRoleChange}
          filterName={name}
          onFilterName={onFilterName}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, px: 2 }}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  padding: 2,
                  borderBottom: "none",
                },
              }}
            >
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!_.isEmpty(filteredUsers) &&
                  filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, id: number) => (
                      <TableRow sx={{ borderBottom: 0 }} key={id} tabIndex={-1}>
                        <TableCell component="th" scope="row" padding="none">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Avatar />
                            <Typography variant="subtitle2" noWrap>
                              {`${row.firstName} ${row.lastName}`}
                            </Typography>
                          </Stack>
                        </TableCell>
                        <TableCell align="left">{row.phone}</TableCell>
                        <TableCell align="left">{row.email}</TableCell>
                        <TableCell align="left">{row.role.name}</TableCell>
                        <TableCell align="left">
                          <Typography
                            p={0.5}
                            borderRadius={1}
                            variant="caption"
                            color={"white"}
                            bgcolor={
                              row.isActive ? "success.dark" : "error.main"
                            }
                          >
                            {row.isActive ? ENABLED.ACTIVE : ENABLED.DISABLED}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <UseMoreMenu
                            isDelete={myRole?.isDelete}
                            isEdit={myRole?.isUpdate}
                            onDelete={() => onDeleteDailogOpen(row)}
                            onEdit={() => onUserEditFormOpen(row)}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                {_.isEmpty(filteredUsers) && (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={TABLE_HEAD.length}>
                      {loading ? <Loader /> : <NoData />}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredUsers.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

export default Users;
