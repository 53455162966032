import React from "react";
import { Route, Routes } from "react-router-dom";
import { useAuth } from "./Apollo/Auth";
import PrivateRouter from "./Components/PrivateRouter";
import Login from "./Pages/Login";
import MainPage from "./Routes/index";

const LandingPage = () => {
  const { isSignedIn } = useAuth();
  const isUserLoggedIn = isSignedIn();

  return (
    <Routes>
      <Route
        path={"/login"}
        element={
          <PrivateRouter
            allowVisit={!isUserLoggedIn}
            redirectTo={"/"}
            component={Login}
          />
        }
      />
      <Route
        path={"*"}
        element={
          <PrivateRouter
            allowVisit={isUserLoggedIn}
            redirectTo={"/login"}
            component={MainPage}
          />
        }
      />
    </Routes>
  );
};

export default LandingPage;
