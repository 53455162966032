import { gql, useMutation } from "@apollo/client";

export const UPDATE_TEMPLATE = gql(`
mutation UpdateTemplate($templateId: String!, $name: String, $isActive: Boolean, $components: JSONObject, $values: JSONObject, $layout: JSON) {
  updateTemplate(templateId: $templateId, name: $name, isActive: $isActive, components: $components, values: $values, layout: $layout) {
    id
    name
    layout
    components
    values
    isApproved
    isActive
    createdAt
    updatedAt
  }
}`);

export function useUpdateTemplateMutation() {
  const [updateTemplate, { data, error, loading }] =
    useMutation(UPDATE_TEMPLATE);
  return { updateTemplate, data, error, loading };
}
