import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import React from "react";
import { CONFIRM } from "../Utils/Const";

interface DialogBoxProps {
  showDialog: boolean;
  cancelNavigation: any;
  confirmNavigation: any;
  message: string;
}

const DailogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
  message,
}) => {
  return (
    <Dialog
      open={showDialog}
      PaperProps={{ sx: { borderRadius: 2 } }}
      onClose={cancelNavigation}
    >
      <DialogContent>
        <DialogContentText fontSize={14}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="small" onClick={confirmNavigation}>
          {CONFIRM.YES}
        </Button>
        <Button size="small" onClick={cancelNavigation}>
          {CONFIRM.NO}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DailogBox;
