import { Box, Breadcrumbs, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

const BreadCrums = ({ links }: any) => {
  return (
    <Breadcrumbs
      separator={
        <Box
          sx={{
            width: 4,
            height: 4,
            mx: 1,
            backgroundColor: "grey.500",
            borderRadius: 50,
          }}
        ></Box>
      }
    >
      <Link underline="none" component={RouterLink} to="/">
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          Home
        </Typography>
      </Link>
      {links.map((x: any, index: number) => {
        const isLast = index === links.length - 1;
        return (
          <Link
            key={`breadcrum-${index}`}
            underline="none"
            component={!isLast ? RouterLink : "div"}
            to={x.href}
          >
            <Typography
              variant="body2"
              sx={{ color: isLast ? "text.secondary" : "text.primary" }}
            >
              {x.name}
            </Typography>
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default BreadCrums;
