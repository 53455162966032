import shortid from "shortid";
import { ROW, COLUMN, COMPONENT, FIELDS } from "./Const";

export const DEFAULT_COLUMN = {
  xs: 12,
  sm: 6,
  md: 4,
  lg: 3,
  type: COLUMN,
};

export const DEFAULT_TEXTFIELD = {
  label: "",
  required: false,
  placeholder: "",
  type: "text",
  multiline: false,
  maxRows: 3,
  minRows: 3,
  regex: undefined,
};

export const DEFAULT_IMAGE_FIELD = {
  width: "100%",
  image: "",
  height: "100%",
  isUpload: true,
};

export const DEFAULT_TYPOGRAPHY = {
  label: "Typography",
  variant: "body2",
  color: "#000",
  bgcolor: "#fff",
  p: "",
  m: "",
};

export const DEFAULT_ALERT = {
  label: "Alert",
  variant: "standard",
  severity: "info",
};

export const DEFAULT_RADIO_GROUP = {
  label: "Radio Group",
  isRow: false,
  required: false,
  options: [],
};

export const DEFAULT_CHECK_GROUP = {
  label: "Check Group",
  isRow: false,
  required: false,
  options: [],
};

export const DEFAULT_MENU_ITEM = {
  label: "",
  multiple: false,
  required: false,
  placeholder: "",
  options: [],
};

export const DEFAULT_RATING = {
  label: "",
  required: false,
  max: 5,
};

export const DEFAULT_DATE_PICKER = {
  label: "",
  disableFuture: false,
  disablePast: false,
  required: false,
  minDate: undefined,
  maxDate: undefined,
};

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const remove = (arr: any, index: number) => [
  ...arr.slice(0, index),
  ...arr.slice(index + 1),
];

export const insert = (arr: any, index: number, newItem: any) => [
  ...arr.slice(0, index),
  newItem,
  ...arr.slice(index),
];

export const reorderChildren = (
  children: any,
  splitDropZonePath: string | any[],
  splitItemPath: string | any[]
) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    const itemIndex = Number(splitItemPath[0]);
    return reorder(children, itemIndex, dropZoneIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  const splitDropZoneChildrenPath = splitDropZonePath.slice(1);
  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: reorderChildren(
      nodeChildren.children,
      splitDropZoneChildrenPath,
      splitItemChildrenPath
    ),
  };

  return updatedChildren;
};

export const removeChildFromChildren = (
  children: any,
  splitItemPath: string | any[]
) => {
  if (splitItemPath.length === 1) {
    const itemIndex = Number(splitItemPath[0]);
    return remove(children, itemIndex);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitItemPath.slice(0, 1));

  const splitItemChildrenPath = splitItemPath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: removeChildFromChildren(
      nodeChildren.children,
      splitItemChildrenPath
    ),
  };

  return updatedChildren;
};

export const addChildToChildren = (
  children: any,
  splitDropZonePath: string | any[],
  item: any
) => {
  if (splitDropZonePath.length === 1) {
    const dropZoneIndex = Number(splitDropZonePath[0]);
    return insert(children, dropZoneIndex, item);
  }

  const updatedChildren = [...children];

  const curIndex = Number(splitDropZonePath.slice(0, 1));

  const splitItemChildrenPath = splitDropZonePath.slice(1);
  const nodeChildren = updatedChildren[curIndex];
  updatedChildren[curIndex] = {
    ...nodeChildren,
    children: addChildToChildren(
      nodeChildren.children,
      splitItemChildrenPath,
      item
    ),
  };

  return updatedChildren;
};

export const handleMoveWithinParent = (
  layout: any,
  splitDropZonePath: any,
  splitItemPath: any
) => {
  return reorderChildren(layout, splitDropZonePath, splitItemPath);
};

export const handleAddColumDataToRow = (layout: any) => {
  const layoutCopy = [...layout];
  const COLUMN_STRUCTURE = {
    id: shortid.generate(),
    children: [],
    ...DEFAULT_COLUMN,
  };

  return layoutCopy.map((row) => {
    if (!row.children.length) {
      row.children = [COLUMN_STRUCTURE];
    }
    return row;
  });
};

export const handleMoveToDifferentParent = (
  layout: any,
  splitDropZonePath: string | any[],
  splitItemPath: any,
  item: any
) => {
  let newLayoutStructure;
  const COLUMN_STRUCTURE = {
    id: shortid.generate(),
    children: [item],
    ...DEFAULT_COLUMN,
  };

  const ROW_STRUCTURE = {
    type: ROW,
    id: shortid.generate(),
  };
  switch (splitDropZonePath.length) {
    case 1: {
      if (item.type === COLUMN) {
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [item],
        };
      } else {
        newLayoutStructure = {
          ...ROW_STRUCTURE,
          children: [COLUMN_STRUCTURE],
        };
      }
      break;
    }
    case 2: {
      if (item.type === COMPONENT) {
        newLayoutStructure = COLUMN_STRUCTURE;
      } else {
        newLayoutStructure = item;
      }

      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }

  let updatedLayout = layout;
  updatedLayout = removeChildFromChildren(updatedLayout, splitItemPath);
  updatedLayout = handleAddColumDataToRow(updatedLayout);
  updatedLayout = addChildToChildren(
    updatedLayout,
    splitDropZonePath,
    newLayoutStructure
  );

  return updatedLayout;
};

export const handleMoveSidebarComponentIntoParent = (
  layout: any,
  splitDropZonePath: string | any[],
  item: any
) => {
  let newLayoutStructure;
  switch (splitDropZonePath.length) {
    case 1: {
      newLayoutStructure = {
        type: ROW,
        id: shortid.generate(),
        children: [
          {
            id: shortid.generate(),
            children: [item],
            ...DEFAULT_COLUMN,
          },
        ],
      };
      break;
    }
    case 2: {
      newLayoutStructure = {
        id: shortid.generate(),
        children: [item],
        ...DEFAULT_COLUMN,
      };
      break;
    }
    default: {
      newLayoutStructure = item;
    }
  }

  return addChildToChildren(layout, splitDropZonePath, newLayoutStructure);
};

export const handleRemoveItemFromLayout = (layout: any, splitItemPath: any) => {
  return removeChildFromChildren(layout, splitItemPath);
};

export const updateColumnLayout = (
  layout: any,
  data: any,
  splitItemPath: any
) => {
  const updatedlayout = [...layout];
  const splitDropZonePath = splitItemPath.split("-");
  const RowIndex = Number(splitDropZonePath[0]);
  const colIndex = Number(splitDropZonePath[1]);
  updatedlayout[RowIndex].children[colIndex] = data;
  return updatedlayout;
};

export const generateComponent = (comp: any) => {
  let updatedComp: any = {};
  if (comp.fieldType === FIELDS.TEXT_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_TEXTFIELD };
  } else if (comp.fieldType === FIELDS.TYPOGRAPHY_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_TYPOGRAPHY };
  } else if (comp.fieldType === FIELDS.ALERT_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_ALERT };
  } else if (comp.fieldType === FIELDS.RADIO_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_RADIO_GROUP };
  } else if (comp.fieldType === FIELDS.CHECK_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_CHECK_GROUP };
  } else if (comp.fieldType === FIELDS.DROP_DOWN_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_MENU_ITEM };
  } else if (comp.fieldType === FIELDS.RATING_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_RATING };
  } else if (comp.fieldType === FIELDS.DATE_PICKER_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_DATE_PICKER };
  } else if (comp.fieldType === FIELDS.IMAGE_FIELD) {
    updatedComp = { ...comp, ...DEFAULT_IMAGE_FIELD };
  }
  return updatedComp;
};

export const generateValue = (fieldType: string) => {
  if (fieldType === FIELDS.TEXT_FIELD) {
    return "";
  } else if (fieldType === FIELDS.RADIO_FIELD) {
    return "";
  } else if (fieldType === FIELDS.CHECK_FIELD) {
    return [];
  } else if (fieldType === FIELDS.DROP_DOWN_FIELD) {
    return "";
  } else if (fieldType === FIELDS.RATING_FIELD) {
    return 0;
  } else if (fieldType === FIELDS.DATE_PICKER_FIELD) {
    return new Date();
  }
};
