import { useEffect, useState } from "react";
import { useAppSelector } from "../Store/Hooks";
import { Screen } from "../Store/Slices/UserSlice";
import { DEFAULT_PATHS } from "../Utils/Const";

export const useRole = (screen: string) => {
  const me = useAppSelector((state) => state.user.me);
  const [myRole, setmyRole] = useState<Screen | undefined>();

  const permittedScreens = [
    ...(me?.role
      ? me?.role?.screens.map((role) => role.name.toLowerCase())
      : []),
    ...DEFAULT_PATHS,
  ];

  useEffect(() => {
    const role: any = me?.role?.screens.find((role) => role.name === screen);
    setmyRole(role);
  }, []);

  return { myRole, permittedScreens };
};

export default useRole;
