import React, { useState, useContext, createContext } from "react";
import CryptoJS from "crypto-js";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  from,
  gql,
  NormalizedCacheObject,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { useDispatch } from "react-redux";
import { createUploadLink } from "apollo-upload-client";
import { AUTH_TOKEN, BEARER, TOKEN_ERR, UNAUTHENTICATED } from "../Utils/Const";
import { updateMe, userLogout } from "../Store/Slices/UserSlice";

const ME = gql(`
query Me {
  me {
    id
    firstName
    lastName
    phone
    email
    isActive
    createdAt
    updatedAt
    role {
      name
      id
      screens {
        name
        isCreate
        isUpdate
        isDelete
        isView
      }
    }
  }
}`);

const URL =
  process.env.NODE_ENV === "development"
    ? `http://localhost:7001/graphql`
    : `${process.env.REACT_APP_SERVER_URL}/graphql`;

const MESSAGE = process.env.authToken || `poorvika_crypto_token`;

export const useProvideAuth = () => {
  const dispatch = useDispatch();

  const getToken = () => {
    let token = localStorage.getItem(AUTH_TOKEN);
    if (!token) return null;
    var bytes = CryptoJS.AES.decrypt(token, MESSAGE);
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  };

  const [authToken, setAuthToken] = useState(() => {
    const initialState = getToken();
    return initialState;
  });

  const isSignedIn = () => {
    if (authToken) {
      return true;
    } else {
      return false;
    }
  };

  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    const client = createApolloClient();
    let res = false;
    const LoginMutation = gql`
      mutation Login($email: String!, $password: String!) {
        login(email: $email, password: $password) {
          token
        }
      }
    `;
    const result = await client
      .mutate({
        mutation: LoginMutation,
        variables: { email, password },
      })
      .catch((e) => {});

    if (result?.data?.login?.token) {
      let token = result.data.login.token;
      setAuthToken(token);
      let ciphertext = CryptoJS.AES.encrypt(token, MESSAGE).toString();
      localStorage.setItem(AUTH_TOKEN, ciphertext);
      res = true;
    } else {
      signOut();
    }
    return res;
  };

  const signOut = () => {
    dispatch(userLogout());
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.clear();
    setAuthToken(null);
  };

  const refreshToken = async () => {
    const client = createApolloClient();
    const refreshMutation = gql`
      mutation Refresh {
        refresh {
          token
        }
      }
    `;
    const result = await client
      .mutate({
        mutation: refreshMutation,
      })
      .catch((e) => {});

    if (result?.data?.refresh?.token) {
      let token = result.data.refresh.token;
      setAuthToken(token);
      let ciphertext = CryptoJS.AES.encrypt(token, MESSAGE).toString();
      localStorage.setItem(AUTH_TOKEN, ciphertext);
      return token;
    } else {
      signOut();
    }
    return null;
  };

  const me = async () => {
    let res = false;
    const client = createApolloClient();

    const result = await client
      .query({
        query: ME,
      })
      .catch((e) => {});

    if (result?.data?.me) {
      let me = result.data.me;
      dispatch(updateMe(me));
      res = true;
    }
    return res;
  };

  const getAuthHeaders = () => {
    if (!authToken) return null;
    return {
      authorization: `${BEARER} ${authToken}`,
    };
  };
  let isRefreshing = false;

  const [pendingRequests, setpendingRequest] = useState<Function[]>([]);

  const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    setpendingRequest([]);
  };

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          if (err.toString() === "TOKEN EXPIRED") {
            if (!isRefreshing) {
              isRefreshing = true;
              return fromPromise(
                refreshToken()
                  .then((token) => {
                    const oldHeaders = operation.getContext().headers;
                    operation.setContext({
                      headers: {
                        ...oldHeaders,
                        authorization: token,
                      },
                    });
                    resolvePendingRequests();
                  })
                  .catch(() => {
                    setpendingRequest([]);
                    return false;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).flatMap(() => {
                resolvePendingRequests();
                isRefreshing = false;
                return forward(operation);
              });
            } else {
              if (operation.operationName !== "Refresh")
                return fromPromise(
                  new Promise<void>((resolve) => {
                    setpendingRequest((prevstate) => [
                      ...prevstate,
                      () => resolve(),
                    ]);
                  })
                ).flatMap(() => {
                  return forward(operation);
                });
            }
          }
          if (err.toString() === "UNAUTHENTICATED") {
            signOut();
          }
          if (
            err.toString() === `Unexpected error value: "Invalid Connection"`
          ) {
          }
        }
      }
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
      }
    }
  );

  const createApolloClient = () => {
    const link = createUploadLink({
      uri: URL,
      headers: getAuthHeaders(),
      credentials: "include",
    });

    return new ApolloClient({
      link: from([errorLink, link]),
      cache: new InMemoryCache(),
      credentials: "include",
    });
  };

  return {
    setAuthToken,
    isSignedIn,
    signIn,
    signOut,
    createApolloClient,
    me,
  };
};

interface IContextProps {
  setAuthToken: React.Dispatch<React.SetStateAction<string | null>>;
  signOut: () => void;
  isSignedIn: () => boolean;
  signIn: ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => Promise<boolean>;
  me: () => Promise<boolean>;
  createApolloClient: () => ApolloClient<NormalizedCacheObject>;
}

const AuthContext = createContext({} as IContextProps);

export function AuthProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  const auth = useProvideAuth();
  return (
    <AuthContext.Provider value={auth}>
      <ApolloProvider client={auth.createApolloClient()}>
        {children}
      </ApolloProvider>
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  return useContext(AuthContext);
};
