import { useEffect, useRef, useState } from "react";

export const useOutsideClick = (initialIsVisible: boolean) => {
  const wrapperRef: any = useRef(null);
  const [isVisible, setIsVisible] = useState(initialIsVisible);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const handleClickOutside = (event: any) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };
  return { wrapperRef, isVisible, setIsVisible };
};
