import { Box } from "@mui/material";
import logo from "../Assets/poorvika-logo.png";
import poorvika from "../Assets/poorvika.png";
import React from "react";

const Logo = ({ width = 70, open = true }) => {
  return <img height={width} src={open ? poorvika : logo} alt="logo" />;
};

export default Logo;
