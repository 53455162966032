import { gql, useMutation } from "@apollo/client";
//@ts-ignore
import { ALL_ROLES } from "./AllRoles";

const CREATE_ROLE = gql`
  mutation CreateRole(
    $name: String!
    $isActive: Boolean!
    $screens: [screenInput]
  ) {
    createRole(name: $name, isActive: $isActive, screens: $screens) {
      id
      name
      isActive
      createdAt
      updatedAt
      screens {
        name
        isCreate
        isUpdate
        isDelete
        isView
      }
      count
    }
  }
`;

export function useCreateRoleMutation() {
  const [addRole, { data, error, loading }] = useMutation(CREATE_ROLE, {
    update(cache, { data }) {
      const createdRole = data?.createRole;
      const existingRoles: any = cache.readQuery({
        query: ALL_ROLES,
      });
      if (createdRole) {
        cache.writeQuery({
          query: ALL_ROLES,
          data: {
            roles: existingRoles
              ? [createdRole, ...existingRoles?.roles]
              : [createdRole],
          },
        });
      }
    },
  });
  return { addRole, data, error, loading };
}
