import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { alpha, styled, useTheme } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  IconButton,
} from "@mui/material";
import NavSection from "../../Components/NavSection";
import useResponsive from "../../Hooks/UseResponsive";
import { useAppSelector } from "../../Store/Hooks";
import navConfig from "./NavConfig";
import arrow from "../../Assets/arr024.svg";
import Logo from "../../Components/Logo";

const DRAWER_WIDTH = 280;

const BASE_WIDTH = 90;

const RootStyle = styled("div")<{ open: boolean }>(({ theme, open }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: open ? DRAWER_WIDTH : BASE_WIDTH,

    transition: "all .3s ease-in",
  },
}));

const AccountStyle = styled("div")<{ open: boolean }>(
  ({ theme, open }: any) => ({
    display: "flex",
    alignItems: "center",
    padding: open ? theme.spacing(2, 2.5) : theme.spacing(1),
    borderRadius: open ? Number(theme.shape.borderRadius) * 1.5 : 0,
    backgroundColor: open ? theme.palette.grey[500_12] : 0,
  })
);

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
  hover,
  sethover,
}: any) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  const me = useAppSelector((state) => state.user.me);

  const theme = useTheme();

  const show = !isDesktop || isOpenSidebar || hover;

  useEffect(() => {
    if (isOpenSidebar && !isDesktop) {
      onCloseSidebar(false);
    }
  }, [pathname]);

  const renderContent = (
    <React.Fragment>
      <Box sx={{ px: 2.5, py: 3 }}>
        {isDesktop && (
          <IconButton
            size="medium"
            onClick={() => onCloseSidebar(!isOpenSidebar)}
            style={{ float: "right", display: show ? "flex" : "none" }}
          >
            <img
              src={arrow}
              width={20}
              style={{
                transition: "all .3s ease-in",
                transform: isOpenSidebar ? "rotate(3.142rad)" : "rotate(0)",
              }}
            />
          </IconButton>
        )}
        <Box mt={-1}>
          <Logo open={show} width={45} />
        </Box>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle open={show}>
            <Avatar alt="photoURL" />
            {show && (
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                  {me?.firstName}
                </Typography>
                <Typography
                  variant="body2"
                  className="lowercase"
                  sx={{ color: "text.secondary" }}
                >
                  {me?.role?.name}
                </Typography>
              </Box>
            )}
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} isMin={show} />

      <Box sx={{ flexGrow: 1 }} />
    </React.Fragment>
  );

  return (
    <RootStyle
      open={isOpenSidebar}
      onMouseEnter={isDesktop ? () => sethover(true) : undefined}
      onMouseLeave={isDesktop ? () => sethover(false) : undefined}
    >
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={() => onCloseSidebar(!isOpenSidebar)}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: show ? DRAWER_WIDTH : BASE_WIDTH,
              zIndex: 900,
              backdropFilter: "blur(6px)",
              WebkitBackdropFilter: "blur(6px)",
              backgroundColor: alpha(theme.palette.background.default, 0.8),
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
