import { gql, useMutation } from "@apollo/client";
import { ALL_ROLES } from "./AllRoles";

const DELETE_ROLE = gql`
  mutation DeleteRole($roleId: String!) {
    deleteRole(roleId: $roleId) {
      id
    }
  }
`;

export function useDeleteRoleMutation() {
  const [deleteRole, { data, error, loading, reset }] = useMutation(
    DELETE_ROLE,
    {
      update(cache, { data }) {
        const deletedId = data?.deleteRole.id;
        const existingRoles: any = cache.readQuery({
          query: ALL_ROLES,
        });

        if (deletedId && existingRoles) {
          cache.writeQuery({
            query: ALL_ROLES,
            data: {
              roles: existingRoles?.roles.filter(
                (x: { id: any }) => x.id !== deletedId
              ),
            },
          });
        }
      },
    }
  );
  return { deleteRole, data, error, loading, reset };
}
