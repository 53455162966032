import {
  Grid,
  Card,
  CardHeader,
  Box,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import React from "react";
import Iconify from "../../Components/Iconify";
import UseMoreMenu from "../../Components/MoreButton";
import useRole from "../../Hooks/UseRole";
import { Role } from "../../Store/Slices/UserSlice";
import { ENABLED, ROLES } from "../../Utils/Const";

export const RoleItem = ({
  role,
  onDelete,
  onEdit,
}: {
  role: Role;
  onDelete: (role: Role) => void;
  onEdit: (role: Role) => void;
}) => {
  let count = role.count ?? 0;
  const { myRole } = useRole("");
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Card
        sx={{
          my: 2,
          border: 1,
          px: 2,
          py: 2,
          borderRadius: 1.5,
          borderColor: "divider",
        }}
      >
        <CardHeader
          sx={{ p: 0 }}
          title={role.name}
          subheader={`${count} users`}
          action={
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"flex-end"}
            >
              <UseMoreMenu
                isDelete={myRole?.isDelete && count < 1 ? true : false}
                isEdit={myRole?.isUpdate}
                onDelete={() => onDelete(role)}
                onEdit={() => onEdit(role)}
              />
              <Box mt={-0.75} pr={1}>
                <Typography
                  p={0.5}
                  borderRadius={1}
                  variant="caption"
                  color={"white"}
                  bgcolor={role.isActive ? "success.dark" : "error.main"}
                >
                  {role.isActive ? ENABLED.ACTIVE : ENABLED.DISABLED}
                </Typography>
              </Box>
            </Box>
          }
        />
        <Divider sx={{ my: 1 }} />
        <Box
          display={"flex"}
          bgcolor={"grey.300"}
          sx={{ p: 1, borderRadius: 1 }}
          justifyContent={"space-between"}
        >
          <Typography variant="subtitle2">{ROLES.SCREENS}</Typography>
          <Typography variant="subtitle2">{ROLES.PERMISSIONS}</Typography>
        </Box>
        {role.screens.map((screen) => (
          <React.Fragment key={screen.name}>
            <Box p={1} display={"flex"} justifyContent={"space-between"}>
              <Typography variant="body2">{screen.name}</Typography>
              <Stack spacing={2} direction={"row"}>
                <Iconify
                  icon={"carbon:data-view-alt"}
                  sx={{ color: screen.isView ? "info.main" : "gray" }}
                />
                <Iconify
                  icon={"carbon:add-filled"}
                  sx={{
                    color: screen.isCreate ? "success.dark" : "gray",
                  }}
                />
                <Iconify
                  icon={"clarity:note-edit-solid"}
                  sx={{
                    color: screen.isUpdate ? "warning.main" : "gray",
                  }}
                />
                <Iconify
                  icon={"fluent:delete-28-filled"}
                  sx={{
                    color: screen.isDelete ? "error.main" : "gray",
                  }}
                />
              </Stack>
            </Box>
            <Divider />
          </React.Fragment>
        ))}
      </Card>
    </Grid>
  );
};
