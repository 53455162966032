import { gql, useQuery } from "@apollo/client";

export const ALL_USERS = gql`
  query Users {
    users {
      id
      firstName
      lastName
      phone
      email
      isActive
      role {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export function useUsersQuery() {
  const { data, error, loading } = useQuery(ALL_USERS);
  const { users } = data || { users: [] };

  return { users, error, loading };
}
