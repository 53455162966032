import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import { styled } from "@mui/material/styles";
import DashboardSidebar from "./Sidebar";
import DashboardNavbar from "./Navbar";
import { Backdrop } from "@mui/material";
import { useAppSelector } from "../../Store/Hooks";
import Loader from "../../Components/Loader";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

export const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

export const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);

  const [hover, sethover] = useState(false);

  const loading = useAppSelector((state) => state.user.loading);

  return (
    <RootStyle>
      <Backdrop
        sx={{
          zIndex: 800,
        }}
        open={loading}
      >
        <Loader />
      </Backdrop>

      <DashboardNavbar
        isOpenSidebar={open}
        onOpenSidebar={() => setOpen(true)}
      />
      <DashboardSidebar
        hover={hover}
        sethover={sethover}
        isOpenSidebar={open}
        onCloseSidebar={(value: boolean) => setOpen(value)}
      />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
