import { Box, Card, Divider, Grid, IconButton, useTheme } from "@mui/material";
import { Breakpoint, Container } from "@mui/system";
import React, { useState } from "react";
import Iconify from "../../Components/Iconify";
import useResponsive from "../../Hooks/UseResponsive";
import { renderComponent } from "./Component";

const Preview = (props: any) => {
  const { layout, components } = props;

  const theme = useTheme();

  const isDesktop = useResponsive("up", "lg");
  const isLaptop = useResponsive("up", "md");
  const isTablet = useResponsive("up", "sm");

  const [screen, setscreen] = useState<Breakpoint>(
    isDesktop ? "lg" : isLaptop ? "md" : isTablet ? "sm" : "xs"
  );

  const options = [
    {
      icon: "ant-design:mobile-twotone",
      size: { width: 28, height: 28 },
      value: "xs",
      show: true,
    },
    {
      icon: "ic:twotone-tablet-mac",
      size: { width: 36, height: 36 },
      value: "sm",
      show: isTablet,
    },
    {
      icon: "emojione-monotone:laptop-computer",
      size: { width: 52, height: 52 },
      value: "md",
      show: isLaptop,
    },
    {
      icon: "emojione-monotone:desktop-computer",
      size: { width: 48, height: 48 },
      value: "lg",
      show: isDesktop,
    },
  ];

  return (
    <Box bgcolor={theme.palette.grey[200]}>
      <Container sx={{ p: 2 }} maxWidth={"lg"}>
        <Box
          display={"flex"}
          alignItems={"baseline"}
          justifyContent={"center"}
          gap={2}
        >
          {options
            .filter((view) => view.show)
            .map((option: any) => (
              <IconButton
                key={option.value}
                disableRipple={true}
                color={option.value === screen ? "primary" : "inherit"}
                onClick={() => setscreen(option.value)}
              >
                <Iconify icon={option.icon} sx={option.size} />
              </IconButton>
            ))}
        </Box>
        <Divider />
        <Container maxWidth={screen} sx={{ flexGrow: 1, mt: 1 }}>
          <Card elevation={12} sx={{ p: 1 }}>
            {layout.map((row: any) => (
              <Grid container key={row.id}>
                {row.children.map(({ children, ...col }: any) => (
                  <Grid item key={col.id} xs={col[screen]} p={1}>
                    {children.map((comp: any) => {
                      return (
                        <React.Fragment key={comp.id}>
                          {renderComponent(components[comp.id])}
                        </React.Fragment>
                      );
                    })}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Card>
        </Container>
      </Container>
    </Box>
  );
};

export default Preview;
