import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import { useAuth } from "../../Apollo/Auth";
import Iconify from "../../Components/Iconify";
import { LOGIN } from "../../Utils/Const";

interface State {
  error: Boolean;
  showPassword: boolean;
}

const LoginForm = () => {
  const { signIn } = useAuth();
  const validateEmail = (email: string | undefined) => {
    return Yup.string().email().isValidSync(email);
  };

  const validatePhone = (phone: string | undefined) => {
    return Yup.string()
      .trim()
      .matches(/^[6789]\d{9}$/, phone)
      .isValidSync(phone);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("required"),
      email: Yup.string()
        .required("Email / Phone is required")
        .test("email", "Email / Phone is invalid", (value) => {
          return validateEmail(value) || validatePhone(value);
        }),
    }),
    onSubmit: async (values, { setSubmitting }) => {
      let result = await signIn(values);
      handleLoginError(!result);
      setSubmitting(false);
    },
  });

  const [values, setValues] = React.useState<State>({
    showPassword: false,
    error: false,
  });

  useEffect(() => {
    if (values.error) {
      handleLoginError(false);
    }
  }, [formik.values]);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleLoginError = (value: boolean) => {
    setValues({
      ...values,
      error: value,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <React.Fragment>
      <Button fullWidth size="large" color="inherit" variant="outlined">
        <Iconify
          icon="bi:cloud-check-fill"
          color="#DF3E30"
          width={22}
          height={22}
        />
        <Typography variant="button" ml={2} sx={{ color: "text.secondary" }}>
          {LOGIN.SSO}
        </Typography>
      </Button>
      <Divider sx={{ my: 3 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {LOGIN.OR}
        </Typography>
      </Divider>
      <form onSubmit={formik.handleSubmit}>
        <Stack spacing={3}>
          {values.error && (
            <Alert
              icon={<ErrorIcon color="error" />}
              sx={{ bgcolor: "error.lighter" }}
              severity="error"
            >
              {LOGIN.USER_ERROR}
            </Alert>
          )}
          <TextField
            id="email"
            name="email"
            type="text"
            fullWidth
            disabled={formik.isSubmitting}
            placeholder={LOGIN.EMAIL_PLACEHOLDER}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.errors.email ? true : false}
            helperText={formik.errors.email}
            value={formik.values.email}
          />
          <TextField
            id="password"
            name="password"
            placeholder={LOGIN.PASSWORD_PLACEHOLDER}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            disabled={formik.isSubmitting}
            error={formik.errors.password ? true : false}
            helperText={formik.errors.password}
            label="Password"
            type={values.showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    <Iconify
                      icon={
                        values.showPassword
                          ? "eva:eye-fill"
                          : "eva:eye-off-fill"
                      }
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ my: 2 }}
        >
          <Link variant="subtitle2" color={"info.main"} underline="hover">
            {LOGIN.FORGOT_PASSWORD}
          </Link>
        </Stack>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={formik.isSubmitting}
        >
          {LOGIN.LOGIN}
        </LoadingButton>
      </form>
    </React.Fragment>
  );
};

export default LoginForm;
