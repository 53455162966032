import { createSlice } from "@reduxjs/toolkit";

export interface User {
  id?: string;
  firstName: string;
  lastName: string;
  photo: null | string;
  phone: string;
  email: string;
  isActive: boolean;
  role: Role | null;
}

export interface Role {
  id?: string;
  name: string;
  screens: Screen[];
  isActive: boolean | null;
  count?: number | null | undefined;
}

export interface Screen {
  name: string;
  isCreate: boolean;
  isView: boolean;
  isUpdate: boolean;
  isDelete: boolean;
}

interface UserState {
  userLogged: boolean;
  me: User | null | undefined;
  loading: boolean;
}

const initialState: UserState = {
  userLogged: false,
  me: null,
  loading: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state) => {
      state.userLogged = true;
    },
    userLogout: (state) => {
      state.me = null;
      state.userLogged = false;
    },
    updateMe: (state, action) => {
      state.me = action.payload;
    },
    isloading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { userLogin, userLogout, updateMe, isloading } = userSlice.actions;

export default userSlice.reducer;
