import { ContentCopy, Delete, Edit } from "@mui/icons-material";
import { Card, IconButton } from "@mui/material";
import React from "react";

const Options = ({
  isHover,
  onDelete,
  onEdit,
  onCopy,
  isEdit = true,
  isDelete = true,
  isCopy = true,
}: any) => {
  return (
    <Card
      elevation={4}
      sx={{
        display: isHover ? "flex" : "none",
        position: "absolute",
        gap: 1,
        borderRadius: 0.5,
        padding: 0.4,
        right: 5,
        top: 2,
      }}
    >
      {isCopy && (
        <IconButton
          disableTouchRipple={true}
          disableRipple
          sx={{ padding: 0 }}
          onClick={onCopy}
        >
          <ContentCopy fontSize="small" color="primary" />
        </IconButton>
      )}
      {isEdit && (
        <IconButton
          disableTouchRipple={true}
          disableRipple
          sx={{ padding: 0 }}
          onClick={onEdit}
        >
          <Edit fontSize="small" color="secondary" />
        </IconButton>
      )}
      {isDelete && (
        <IconButton
          disableTouchRipple={true}
          disableRipple
          sx={{ padding: 0 }}
          onClick={onDelete}
        >
          <Delete fontSize="small" color="error" />
        </IconButton>
      )}
    </Card>
  );
};

export default Options;
