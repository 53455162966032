import { gql, useMutation } from "@apollo/client";
//@ts-ignore
import { ALL_TEMPLATES } from "./AllTemplates";

const CREATE_TEMPLATE = gql`
  mutation CreateTemplate(
    $name: String!
    $components: JSONObject!
    $values: JSONObject!
    $layout: JSON!
  ) {
    createTemplate(
      name: $name
      components: $components
      values: $values
      layout: $layout
    ) {
      id
      name
      components
      layout
      values
      isApproved
      isActive
      createdAt
      updatedAt
    }
  }
`;

export function useCreateTemplateMutation() {
  const [addTemplate, { data, error, loading }] = useMutation(CREATE_TEMPLATE, {
    update(cache, { data }) {
      const { createTemplate } = data;
      const existingTemplates: any = cache.readQuery({
        query: ALL_TEMPLATES,
      });
      if (createTemplate) {
        cache.writeQuery({
          query: ALL_TEMPLATES,
          data: {
            templates: existingTemplates
              ? [createTemplate, ...existingTemplates?.templates]
              : [createTemplate],
          },
        });
      }
    },
  });
  return { addTemplate, data, error, loading };
}
