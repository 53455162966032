import { gql, useQuery } from "@apollo/client";

export const ALL_TEMPLATES = gql`
  query Query {
    templates {
      id
      name
      layout
      components
      values
      isApproved
      isActive
      createdAt
      updatedAt
    }
  }
`;

export function useTemplatesQuery() {
  const { data, error, loading } = useQuery(ALL_TEMPLATES);
  const { templates } = data || { templates: [] };

  return { templates, error, loading };
}
