import { Button, Container, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { useRolesQuery } from "../../Api/Roles/AllRoles";
import { useDeleteRoleMutation } from "../../Api/Roles/DeleteRole";
import BreadCrums from "../../Components/Breadcrum";
import DailogBox from "../../Components/ConfirmDailog";
import Iconify from "../../Components/Iconify";
import Loader from "../../Components/Loader";
import AlertBar from "../../Components/SnackBar";
import useRole from "../../Hooks/UseRole";
import { useAppDispatch } from "../../Store/Hooks";
import { isloading, Role } from "../../Store/Slices/UserSlice";
import { ROLES } from "../../Utils/Const";
import { RolesForm } from "./RoleForm";
import { RoleItem } from "./RoleItems";

const roleIntialValue: Role = {
  name: "",
  isActive: true,
  screens: [],
};

const Roles = () => {
  const { loading, roles } = useRolesQuery();
  const dispatch = useAppDispatch();
  const [selected, setselected] = useState<Role | null>(null);
  const [openDeleteDailog, setopenDeleteDailog] = useState(false);
  const { deleteRole } = useDeleteRoleMutation();
  const [showError, setshowError] = useState(false);
  const [openForm, setopenForm] = useState(false);
  const [isEdit, setisEdit] = useState(false);

  const { myRole } = useRole("Roles");

  const onDeleteDailogOpen = (role: Role) => {
    setselected(role);
    setopenDeleteDailog(true);
  };

  const onDeleteDailogClose = () => {
    setselected(null);
    setopenDeleteDailog(false);
  };

  const onDeleteRole = async () => {
    const id = selected?.id;
    onDeleteDailogClose();
    dispatch(isloading(true));
    await deleteRole({
      variables: {
        roleId: id,
      },
    })
      .catch((err) => setshowError(true))
      .finally(() => dispatch(isloading(false)));
  };

  const onRoleFormClose = () => {
    setopenForm(false);
    if (selected !== null && isEdit) {
      setselected(null);
      setisEdit(false);
    }
  };

  const onRoleEditFormOpen = (role: Role) => {
    setselected(role);
    setisEdit(true);
    setopenForm(true);
  };

  return (
    <Container>
      <RolesForm
        open={openForm}
        roles={roles}
        role={isEdit ? selected : roleIntialValue}
        isEdit={isEdit}
        handleClose={onRoleFormClose}
      />
      <DailogBox
        showDialog={openDeleteDailog}
        cancelNavigation={onDeleteDailogClose}
        confirmNavigation={onDeleteRole}
        message={`${ROLES.CONFIRM_DELETE} ${selected?.name} ${ROLES.CONFIRM_DELETE1}`}
      />
      <AlertBar
        open={showError}
        handleClose={() => setshowError(false)}
        message={ROLES.DELETE_FAILED}
      />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4">{ROLES.ROLES}</Typography>
        {myRole?.isCreate && (
          <Button
            variant="contained"
            onClick={() => setopenForm(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {ROLES.NEW_ROLE}
          </Button>
        )}
      </Stack>
      <BreadCrums links={[{ name: ROLES.ROLES, href: "" }]} />
      {loading ? (
        <Loader sx={{ height: 300 }} />
      ) : (
        <React.Fragment>
          <Grid container spacing={3}>
            {roles.map((role: Role) => (
              <RoleItem
                key={role.id}
                role={role}
                onEdit={onRoleEditFormOpen}
                onDelete={onDeleteDailogOpen}
              />
            ))}
          </Grid>
        </React.Fragment>
      )}
    </Container>
  );
};

export default Roles;
