export const AUTH_TOKEN = "auth_token";

export const BEARER = "Bearer";

export const TOKEN_ERR = "TOKEN EXPIRED";

export const UNAUTHENTICATED = "UNAUTHENTICATED";

export const PAGE_NOT_FOUND = "Sorry, page not found!";

export const RETURN_HOME = "Go to Home";

export const LOGOUT = "Logout";

export const CONFIRM_CHANGES = "There are some changes. Are you sure to leave?";

export const LOGIN = {
  SSO: "Sign in with SSO",
  OR: "OR",
  WELCOME: "Hi, Welcome Back",
  SIGNIN: "Sign in to Poorvika Audit",
  CREDENTIALS: "Enter your details below.",
  USER_ERROR: "Invalid Email / Password",
  EMAIL_PLACEHOLDER: "Enter email / phone",
  PASSWORD_PLACEHOLDER: "Enter your password",
  FORGOT_PASSWORD: "Forgot Password?",
  LOGIN: "Login",
};

export const ROLES = {
  ROLES: "Roles",
  NEW_ROLE: "New Role",
  SCREENS: "Screens",
  PERMISSIONS: "Pemissions",
  CONFIRM_DELETE: "Are you sure to delete the",
  CONFIRM_DELETE1: "role?",
  DELETE_FAILED: "Failed to delete the Role",
  CREATE_ROLE: "Create a new role",
  UPDATE_ROLE: "Update role details",
};

export const TEMPLATES = {
  TEMPLATES: "Templates",
  NEW_TEMPLATE: "New Template",
  CONFIRM_DELETE: " Are you sure to delete Template? ",
  DELETE_FAILED: "Failed to delete the Template",
};

export const USERS = {
  CREATE_USER: "Create a new user",
  UPDATE_USER: "Update user details",
  REMOVE_PHOTO: "Remove photo",
  UPLOAD_PHOTO: "Upload photo",
  NEW_USER: "New User",
  CONFIRM_DELETE: " Are you sure to delete User? ",
  DELETE_FAILED: "Failed to delete the user",
};

export const IMAGE_SIZE_ERR = "File size exceeds the limit";

export const BUTTON = {
  UPDATE: "update",
  CREATE: "create",
  CANCEL: "cancel",
};

export const ENABLED = {
  ACTIVE: "Active",
  DISABLED: "Disabled",
};

export const CONFIRM = {
  YES: "Yes",
  NO: "No",
};

export const MENU_OPTIONS = [
  {
    label: "Home",
    icon: "eva:home-fill",
    linkTo: "/",
  },
  {
    label: "Profile",
    icon: "eva:person-fill",
    linkTo: "#",
  },
];

export const FIELD_OPTIONS = [
  {
    id: "textField",
    label: "Text Field",
    icon: "ph:textbox-duotone",
  },
  {
    id: "radioGroup",
    label: "Radio Group",
    icon: "ph:radio-button-duotone",
  },
  {
    id: "checkGroup",
    label: "Checkbox Group",
    icon: "ic:twotone-library-add-check",
  },
  {
    id: "dropDown",
    label: "Drop Down",
    icon: "mdi:form-dropdown",
  },
  {
    id: "datePicker",
    label: "Date Picker",
    icon: "ic:twotone-date-range",
  },
  {
    id: "imageUpload",
    label: "Image Upload",
    icon: "ic:twotone-image",
  },
  {
    id: "textArea",
    label: "Text Area",
    icon: "ic:twotone-text-snippet",
  },
  {
    id: "card",
    label: "Card",
    icon: "ant-design:credit-card-twotone",
  },
];

export const PAGES = ["Roles", "Users", "Templates"];

export const DEFAULT_PATHS = ["/", "*", "404", "", "dashboard"];

export const ROLE_OPTIONS = ["isView", "isCreate", "isUpdate", "isDelete"];
