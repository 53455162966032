import { Box, useTheme } from "@mui/material";
import React from "react";
import { useDrop } from "react-dnd";
import { COLUMN, COMPONENT, ROW, SIDEBAR_ITEM } from "./Const";

const ACCEPTS = [SIDEBAR_ITEM, COMPONENT, ROW, COLUMN];

const DropZone = ({
  data,
  onDrop,
  isLast = false,
  isHorizontal = false,
}: any) => {
  const theme = useTheme();

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item: any) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;

      // sidebar items can always be dropped anywhere
      if (!itemPath) {
        // if (data.childrenCount >= 3) {
        //  return false;
        // }
        return true;
      }

      const splitItemPath = itemPath.split("-");

      const dropZonePathRowIndex = splitDropZonePath[0];
      const itemPathRowIndex = splitItemPath[0];
      const diffRow = dropZonePathRowIndex !== itemPathRowIndex;
      if (
        diffRow &&
        splitDropZonePath.length === 2 &&
        data.childrenCount >= 3
      ) {
        return false;
      }

      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      if (parentDropInChild) return false;

      if (itemPath === dropZonePath) return false;

      if (splitItemPath.length === splitDropZonePath.length) {
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);

        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
        const currentDropZoneIndex = Number(splitDropZonePath.slice(-1)[0]);

        if (pathToItem === pathToDropZone) {
          const nextDropZoneIndex = currentItemIndex + 1;
          if (nextDropZoneIndex === currentDropZoneIndex) return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const isActive = isOver && canDrop;
  return (
    <Box
      sx={{
        flex: !isHorizontal && isLast ? "1 1 auto" : "0 0 auto",
        height: isHorizontal ? "auto" : 20,
        width: !isHorizontal ? 1 : 20,
        minHeight: 20,
        backgroundColor: isActive ? theme.palette.primary.light : "inherit",
        transition: "200ms all",
        "&:nth-of-type(2n)": {
          display: "none",
        },
      }}
      ref={drop}
    ></Box>
  );
};

export default DropZone;
