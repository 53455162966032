import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import DailogBox from "../../Components/ConfirmDailog";
import Loader from "../../Components/Loader";
import AlertBar from "../../Components/SnackBar";
import { Close, Delete } from "@mui/icons-material";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { isloading, Role, User } from "../../Store/Slices/UserSlice";
import Iconify from "../../Components/Iconify";
import { AntSwitch } from "../../Utils/Components/AntSwitch";
import {
  BUTTON,
  CONFIRM_CHANGES,
  IMAGE_SIZE_ERR,
  USERS,
} from "../../Utils/Const";
import { useRoleListQuery } from "../../Api/Roles/RolesList";
import { useCreateUserMutation } from "../../Api/Users/CreateUser";
import { useValidateMutation } from "../../Api/Users/Validations";
import { useUpdateUserMutation } from "../../Api/Users/UpdateUser";

export const UserForm = ({
  user,
  open,
  handleClose,
  isEdit = false,
}: {
  user?: User | null;
  open: boolean;
  handleClose: () => void;
  isEdit?: boolean;
}) => {
  const { roles } = useRoleListQuery();

  const { addUser } = useCreateUserMutation();

  const { updateUser } = useUpdateUserMutation();

  const [showDialog, setshowDialog] = useState(false);

  const [showPrompt, setshowPrompt] = useState(false);

  const [loading, setloading] = useState(false);

  const [imageError, setimageError] = useState(false);

  const uploadRef: any = useRef(null);

  const { validateEmail, validatePhone } = useValidateMutation();

  useEffect(() => {
    setisError(false);
    setshowDialog(false);
    setshowPrompt(false);
    setloading(false);
  }, [open]);

  const [isError, setisError] = useState(false);

  const onhandleClose = () => {
    if (showDialog) {
      setshowPrompt(true);
    } else {
      handleClose();
    }
  };

  const onSubmit = async (values: any, { setFieldError }: any) => {
    setloading(true);
    if (!isEdit) {
      let res = false;
      let phoneValid = await validatePhone({
        variables: { phone: values.phone.toString() },
      });
      let emailValid = await validateEmail({
        variables: { email: values.email },
      });
      if (phoneValid.data && phoneValid.data.validatePhone.isValid) {
        setFieldError("phone", "Phone number already exist");
        res = true;
      }
      if (emailValid.data && emailValid.data.validateEmail.isValid) {
        setFieldError("email", "Email already exist");
        res = true;
      }
      if (!res) {
        setshowDialog(false);
        await addUser({
          variables: {
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone.toString(),
            role: values.role,
            isActive: values.isActive,
          },
        })
          .then(() => {
            handleClose();
          })
          .catch(() => setisError(true))
          .finally(() => setloading(false));
      } else {
        setloading(false);
      }
    } else {
      let query: any = {};
      if (values.firstName.trim() !== user?.firstName.trim()) {
        query["firstName"] = values.firstName.trim();
      }
      if (values.lastName.trim() !== user?.lastName.trim()) {
        query["lastName"] = values.lastName.trim();
      }
      if (values.role && values.role !== user?.role?.id) {
        query["role"] = values.role;
      }
      if (values.isActive !== user?.isActive) {
        query["isActive"] = values.isActive;
      }
      if (!_.isEmpty(query)) {
        query["userId"] = values.id;
        setshowDialog(false);
        await updateUser({
          variables: query,
        })
          .then(() => {
            handleClose();
          })
          .catch(() => setisError(true))
          .finally(() => setloading(false));
      }
    }
  };

  const validatePhoneNumber = (phone: string | undefined) => {
    return Yup.string()
      .trim()
      .matches(/^[6789]\d{9}$/, phone)
      .isValidSync(phone);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    phone: Yup.string()
      .trim()
      .required("Phone number is required")
      .test("Phone is invalid", (value) => {
        return validatePhoneNumber(value);
      }),
    email: Yup.string().required("Required").email("Invalid email"),
  });

  const handleErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setisError(false);
    setimageError(false);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      PaperProps={{ sx: { borderRadius: 2, flexGrow: 1 } }}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={onhandleClose}
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
          color: (theme: Theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <Backdrop sx={{ zIndex: 1000 }} open={loading}>
        <Loader />
      </Backdrop>
      <DailogBox
        showDialog={showPrompt}
        confirmNavigation={() => {
          handleClose();
          setshowPrompt(false);
        }}
        cancelNavigation={() => setshowPrompt(false)}
        message={CONFIRM_CHANGES}
      />
      <AlertBar
        open={isError || imageError}
        message={
          imageError
            ? IMAGE_SIZE_ERR
            : `Failed to ${isEdit ? BUTTON.UPDATE : BUTTON.CREATE} role`
        }
        handleClose={handleErrorClose}
      />
      <Formik
        initialValues={{
          ...user,
          photoUpload: null,
          role: user?.role?.id ?? "",
        }}
        enableReinitialize={true}
        validationSchema={!isEdit && validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          initialValues,
          setFieldError,
          setFieldValue,
        }) => {
          useEffect(() => {
            setshowDialog(!_.isEqual(user, values));
          }, [values]);

          useEffect(() => {
            setshowDialog(false);
          }, [open]);
          const isImage = values.photoUpload || values.photo;
          return (
            <Box>
              <DialogTitle sx={{ py: 1, px: 2, bgcolor: "grey.300" }}>
                {isEdit ? USERS.UPDATE_USER : USERS.CREATE_USER}
              </DialogTitle>
              <Divider />
              <DialogContent>
                <Box
                  pb={1}
                  className="box-center"
                  flexDirection={"column"}
                  alignItems={"center"}
                  gap={2}
                  textAlign={"center"}
                >
                  <Box
                    border={"dashed"}
                    width={140}
                    height={140}
                    borderColor={"divider"}
                    borderRadius={50}
                  >
                    <IconButton
                      sx={{ width: 1, height: 1, borderRadius: 50 }}
                      disableRipple
                      onClick={() => uploadRef.current.click()}
                    >
                      <input
                        type="file"
                        ref={uploadRef}
                        accept={"image/*"}
                        style={{ display: "none" }}
                        name={"photoUpload"}
                        onChange={(event) => {
                          if (event.target.files && event.target.files[0]) {
                            const fileSize =
                              event.target.files[0].size / 1024 / 1024;
                            if (fileSize <= 5) {
                              setFieldValue(
                                "photoUpload",
                                event.target.files && event.target.files[0]
                              );
                              setFieldValue("photo", null);
                            } else {
                              setimageError(true);
                            }
                          }
                        }}
                      />
                      <Box
                        width={1}
                        height={1}
                        className={"box-center"}
                        position={"relative"}
                        borderRadius={50}
                      >
                        {isImage && (
                          <Box
                            width={1}
                            height={1}
                            className={"box-center"}
                            component={"img"}
                            sx={{}}
                            src={
                              values.photoUpload
                                ? window.URL.createObjectURL(values.photoUpload)
                                : values.photo ?? undefined
                            }
                            borderRadius={50}
                          />
                        )}
                        <Box
                          width={1}
                          height={1}
                          position={"absolute"}
                          bgcolor={!isImage ? "grey.200" : "transparent"}
                          sx={{
                            "& div": {
                              display: !isImage ? "flex" : "none",
                            },
                            "&:hover": {
                              "& div": { display: "flex" },
                              bgcolor: isImage ? "black" : "grey.100",
                              opacity: !isImage ? 1 : 0.7,
                              color: !isImage ? "gray" : "grey.100",
                            },
                          }}
                          borderRadius={50}
                          className={"box-center"}
                        >
                          <Box
                            display={"flex"}
                            component={"div"}
                            flexDirection={"column"}
                            gap={0.5}
                            alignItems={"center"}
                          >
                            <Iconify
                              sx={{ width: 24 }}
                              icon={"mdi:camera-plus"}
                            />
                            <Typography variant="caption">
                              {USERS.UPLOAD_PHOTO}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </IconButton>
                  </Box>
                  {isImage && (
                    <IconButton
                      sx={{ my: -1 }}
                      color="error"
                      disableRipple
                      onClick={() =>
                        setFieldValue(
                          values.photoUpload ? "photoUpload" : "photo",
                          null
                        )
                      }
                    >
                      <Delete sx={{ fontSize: 14 }} />
                      <Typography sx={{ ml: 0.5 }} variant="caption">
                        {USERS.REMOVE_PHOTO}
                      </Typography>
                    </IconButton>
                  )}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name={"firstName"}
                      value={values.firstName}
                      error={errors.firstName ? true : false}
                      helperText={errors.firstName as string | undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={"First Name"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"lastName"}
                      value={values.lastName}
                      error={errors.lastName ? true : false}
                      helperText={errors.lastName as string | undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={"Last Name"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      label={"Role"}
                      name={"role"}
                      value={values.role}
                      error={errors.role ? true : false}
                      helperText={errors.role as string | undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      SelectProps={{
                        MenuProps: {
                          PaperProps: {
                            sx: {
                              p: 1,
                              "& .MuiMenuItem-root": {
                                typography: "body2",
                                borderRadius: 0.75,
                                marginY: 0.5,
                              },
                            },
                          },
                        },
                      }}
                    >
                      {roles
                        .filter((x: Role) => x.isActive)
                        .map((option: Role) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      type={"email"}
                      name={"email"}
                      disabled={isEdit}
                      value={values.email}
                      error={errors.email ? true : false}
                      helperText={errors.email as string | undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={"Email"}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name={"phone"}
                      type={"number"}
                      disabled={isEdit}
                      inputProps={{ pattern: "([^0-9]*)" }}
                      value={values.phone}
                      error={errors.phone ? true : false}
                      helperText={errors.phone as string | undefined}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      label={"Phone"}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <Box
                display={"flex"}
                alignItems={"center"}
                px={2}
                py={1}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  sx={{ mx: 1 }}
                  componentsProps={{
                    typography: { sx: { ml: 1 }, variant: "subtitle2" },
                  }}
                  control={
                    <AntSwitch
                      checked={values.isActive as boolean}
                      onChange={(e) =>
                        setFieldValue("isActive", e.target.checked)
                      }
                      name="isActive"
                    />
                  }
                  label="Active"
                />
                <Box display={"flex"} gap={1}>
                  <LoadingButton
                    type="submit"
                    disabled={!isValid || !showDialog}
                    loading={isSubmitting}
                    onClick={() => onSubmit(values, { setFieldError })}
                    variant="contained"
                    sx={{ width: 100 }}
                  >
                    {isEdit ? BUTTON.UPDATE : BUTTON.CREATE}
                  </LoadingButton>
                  <Button onClick={onhandleClose}>{BUTTON.CANCEL}</Button>
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
};
