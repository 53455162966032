import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useAppDispatch } from "../../Store/Hooks";
import { onOpen } from "../../Store/Slices/DrawerSlice";
import { COMPONENT, FIELDS } from "./Const";
import DAlert from "./DComponents/DAlert";
import { DCheckGroup } from "./DComponents/DCheckGroup";
import DDatePicker from "./DComponents/DDatePicker";
import DImage from "./DComponents/DImage";
import DMenuItem from "./DComponents/DMenuItem";
import DRadioGroup from "./DComponents/DRadioGroup";
import DRating from "./DComponents/DRating";
import DTextField from "./DComponents/DTextField";
import DTypography from "./DComponents/DTypography";
import Options from "./Options";

const Component = ({
  data,
  components,
  path,
  isHovered,
  onDelete,
  onCopy,
}: any) => {
  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const [{ isDragging }, drag] = useDrag({
    type: COMPONENT,
    item: { type: COMPONENT, id: data.id, path },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  const [isHover, setisHover] = useState(false);

  useEffect(() => {
    isHovered(isHover);
  }, [isHover]);

  const component = components[data.id];

  return (
    <Box
      ref={ref}
      onMouseOver={() => setisHover(true)}
      onMouseOut={() => setisHover(false)}
      sx={{
        position: "relative",
        px: 2,
      }}
    >
      {renderComponent(component)}
      <Options
        isHover={isHover}
        onDelete={() => {
          onDelete({
            id: data.id,
            type: COMPONENT,
            children: data.children,
            path,
          });
          isHovered(false);
        }}
        onCopy={() => {
          onCopy({
            id: data.id,
            type: COMPONENT,
            data: data,
            children: data.children,
            path,
          });
          isHovered(false);
        }}
        onEdit={() => {
          dispatch(
            onOpen({
              item: { type: COMPONENT, id: data.id, path, data: data },
              isOpen: true,
            })
          );
        }}
      />
    </Box>
  );
};

export const renderComponent = ({ fieldType, ...prop }: any) => {
  if (fieldType === FIELDS.TEXT_FIELD) {
    return <DTextField {...prop} />;
  } else if (fieldType === FIELDS.TYPOGRAPHY_FIELD) {
    return <DTypography {...prop} />;
  } else if (fieldType === FIELDS.ALERT_FIELD) {
    return <DAlert {...prop} />;
  } else if (fieldType === FIELDS.RADIO_FIELD) {
    return <DRadioGroup {...prop} />;
  } else if (fieldType === FIELDS.CHECK_FIELD) {
    return <DCheckGroup {...prop} />;
  } else if (fieldType === FIELDS.DROP_DOWN_FIELD) {
    return <DMenuItem {...prop} />;
  } else if (fieldType === FIELDS.RATING_FIELD) {
    return <DRating {...prop} />;
  } else if (fieldType === FIELDS.DATE_PICKER_FIELD) {
    return <DDatePicker {...prop} />;
  } else if (fieldType === FIELDS.IMAGE_FIELD) {
    return <DImage {...prop} />;
  }
};

export default Component;
