import { useRef, useState } from "react";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import React from "react";
import Iconify from "./Iconify";

export default function UseMoreMenu({
  isDelete = true,
  isEdit = true,
  isOther = false,
  otherLabel = "",
  otherIcon = "",
  onOther,
  onDelete,
  onEdit,
}: any) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const onEditClicked = () => {
    onEdit();
    setIsOpen(false);
  };

  const onDeleteClicked = () => {
    onDelete();
    setIsOpen(false);
  };
  const onOtherClicked = () => {
    onOther();
    setIsOpen(false);
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      {(isDelete || isEdit) && (
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: {
              width: 200,
              maxWidth: "100%",
              "& .MuiMenuItem-root": {
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
          anchorOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
        >
          <Stack sx={{ p: 0.5 }}>
            {isOther && (
              <MenuItem sx={{ color: "info.main" }} onClick={onOtherClicked}>
                <ListItemIcon>
                  <Iconify
                    sx={{ color: "info.main" }}
                    icon={otherIcon}
                    width={16}
                    height={20}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={otherLabel}
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
            {isEdit && (
              <MenuItem
                sx={{ color: "text.secondary" }}
                onClick={onEditClicked}
              >
                <ListItemIcon>
                  <Iconify icon="entypo:edit" width={16} height={20} />
                </ListItemIcon>
                <ListItemText
                  primary="Edit"
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
            {isDelete && (
              <MenuItem sx={{ color: "error.main" }} onClick={onDeleteClicked}>
                <ListItemIcon>
                  <Iconify
                    icon="ic:baseline-delete-outline"
                    sx={{ color: "error.main" }}
                    width={20}
                    height={24}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Delete"
                  primaryTypographyProps={{ variant: "body2", fontWeight: 600 }}
                />
              </MenuItem>
            )}
          </Stack>
        </Menu>
      )}
    </>
  );
}
