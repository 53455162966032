import { Box, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import React from "react";

const DDatePicker = (props: any) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MobileDatePicker
        {...props}
        showToolbar={false}
        value={new Date()}
        views={["year", "day"]}
        onChange={() => {}}
        renderInput={(params) => (
          <TextField size="small" {...params} {...props} />
        )}
      />
    </LocalizationProvider>
  );
};

export default DDatePicker;
