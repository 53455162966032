import {
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DailogBox from "../../Components/ConfirmDailog";
import Loader from "../../Components/Loader";
import AlertBar from "../../Components/SnackBar";
import { Close } from "@mui/icons-material";
import { Formik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Role, Screen } from "../../Store/Slices/UserSlice";
import Iconify from "../../Components/Iconify";
import CustomCheckbox from "../../Components/Checkbox";

import { AntSwitch } from "../../Utils/Components/AntSwitch";
import {
  BUTTON,
  CONFIRM_CHANGES,
  PAGES,
  ROLES,
  ROLE_OPTIONS,
} from "../../Utils/Const";
import { useCreateRoleMutation } from "../../Api/Roles/CreateRole";
import { useUpdateRoleMutation } from "../../Api/Roles/UpdateRole";

export const RolesForm = ({
  role,
  open,
  handleClose,
  roles,
  isEdit = false,
}: {
  role?: Role | null;
  roles?: Role[];
  open: boolean;
  handleClose: () => void;
  isEdit?: boolean;
}) => {
  const [showDialog, setshowDialog] = useState(false);

  const [showPrompt, setshowPrompt] = useState(false);

  const [loading, setloading] = useState(false);

  const { addRole } = useCreateRoleMutation();

  const { updateRole } = useUpdateRoleMutation();

  useEffect(() => {
    setisError(false);
    setshowDialog(false);
    setshowPrompt(false);
    setloading(false);
  }, [open]);

  const [isError, setisError] = useState(false);

  const onhandleClose = () => {
    if (showDialog) {
      setshowPrompt(true);
    } else {
      handleClose();
    }
  };

  const onSubmit = async (values: any) => {
    setshowDialog(false);
    if (isEdit) {
      setloading(true);
      await updateRole({
        variables: {
          roleId: role?.id as string,
          isActive: values.isActive,
          screens: values.screens
            .filter((x: any) => {
              return (
                x["isView"] || x["isCreate"] || x["isUpdate"] || x["isDelete"]
              );
            })
            .map((x: any) => {
              let { __typename, ...screen } = x;
              return screen;
            }),
        },
      })
        .then(() => {
          handleClose();
        })
        .catch(() => setisError(true))
        .finally(() => setloading(false));
    } else {
      await addRole({ variables: { ...values } })
        .then(() => {
          handleClose();
        })
        .catch(() => setisError(true))
        .finally(() => setloading(false));
    }
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Required")
      .test(
        "Unique",
        "Name need te be unique",
        (values: string | undefined) => {
          return roles &&
            roles.find(
              (x: any) => x.name.toUpperCase() === values?.trim().toUpperCase()
            ) === undefined
            ? true
            : false;
        }
      ),
  });

  const handleErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setisError(false);
  };

  return (
    <Dialog
      maxWidth={"xs"}
      PaperProps={{ sx: { borderRadius: 2, flexGrow: 1 } }}
      open={open}
    >
      <IconButton
        aria-label="close"
        onClick={onhandleClose}
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
          color: (theme: Theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>
      <Backdrop sx={{ zIndex: 1000 }} open={loading}>
        <Loader />
      </Backdrop>
      <DailogBox
        showDialog={showPrompt}
        confirmNavigation={() => {
          handleClose();
          setshowPrompt(false);
        }}
        cancelNavigation={() => setshowPrompt(false)}
        message={CONFIRM_CHANGES}
      />
      <AlertBar
        open={isError}
        message={`Failed to ${isEdit ? BUTTON.UPDATE : BUTTON.CREATE} role`}
        handleClose={handleErrorClose}
      />
      <Formik
        initialValues={role as Role}
        enableReinitialize={true}
        validationSchema={!isEdit && validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          values,
          handleChange,
          handleBlur,
          isValid,
          isSubmitting,
          initialValues,
          setFieldValue,
        }) => {
          useEffect(() => {
            setshowDialog(!_.isEqual(role, values));
          }, [values]);

          useEffect(() => {
            initialValues = role as Role;
          }, [open]);

          const updateScreen = (
            screen: string,
            option: string,
            value: boolean
          ) => {
            let { screens } = values;
            let isScreen = screens.find((x: any) => x.name === screen);
            let newScreen: any;
            if (isScreen !== undefined) {
              if (value) {
                isScreen = {
                  ...isScreen,
                  [option]: false,
                };
              } else {
                isScreen = {
                  ...isScreen,
                  [option]: true,
                };
              }
              if (Object.keys(isScreen).length === 1) {
                screens = [
                  ...screens.filter((x: any) => x.name !== isScreen?.name),
                ];
              } else {
                screens = [
                  ...screens.filter((x: any) => x.name !== isScreen?.name),
                  isScreen,
                ];
              }
            } else {
              newScreen = {
                name: screen,
                [option]: true,
              };
              if (option !== "isView") {
                newScreen["isView"] = true;
              }
              screens = [...screens, newScreen];
            }
            setFieldValue("screens", screens);
          };

          return (
            <Box>
              <DialogTitle sx={{ py: 1, px: 2, bgcolor: "grey.300" }}>
                {isEdit ? ROLES.UPDATE_ROLE : ROLES.CREATE_ROLE}
              </DialogTitle>
              <Divider />
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    {isEdit ? (
                      <Typography variant="h6">{values.name}</Typography>
                    ) : (
                      <TextField
                        name={"name"}
                        value={values.name}
                        disabled={isEdit}
                        error={errors.name ? true : false}
                        helperText={errors.name as string | undefined}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        fullWidth
                        label={"Name"}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <RolesHeader />
                    {PAGES.map((page) => (
                      <ScreenRoles
                        key={page}
                        screen={page}
                        screens={values.screens}
                        onChange={updateScreen}
                      />
                    ))}
                  </Grid>
                </Grid>
              </DialogContent>
              <Divider />
              <Box
                display={"flex"}
                alignItems={"center"}
                px={2}
                py={1}
                justifyContent={"space-between"}
              >
                <FormControlLabel
                  sx={{ mx: 1 }}
                  componentsProps={{
                    typography: { sx: { ml: 1 }, variant: "subtitle2" },
                  }}
                  control={
                    <AntSwitch
                      checked={values.isActive as boolean}
                      onChange={(e) =>
                        setFieldValue("isActive", e.target.checked)
                      }
                      name="isActive"
                    />
                  }
                  label="Active"
                />
                <Box display={"flex"} gap={1}>
                  <LoadingButton
                    type="submit"
                    disabled={!isValid || !showDialog}
                    loading={isSubmitting}
                    onClick={() => onSubmit(values)}
                    variant="contained"
                    sx={{ width: 100 }}
                  >
                    {isEdit ? BUTTON.UPDATE : BUTTON.CREATE}
                  </LoadingButton>
                  <Button onClick={onhandleClose}>{BUTTON.CANCEL}</Button>
                </Box>
              </Box>
            </Box>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const RolesHeader = () => {
  return (
    <Grid container sx={{ bgcolor: "grey.300", p: 1, borderRadius: 1 }}>
      <Grid item xs={4}>
        <Typography variant="subtitle2">Screens</Typography>
      </Grid>
      <Grid item xs={2} textAlign={"center"}>
        <Iconify icon={"carbon:data-view-alt"} sx={{ color: "info.main" }} />
      </Grid>
      <Grid item xs={2} textAlign={"center"}>
        <Iconify
          icon={"carbon:add-filled"}
          sx={{
            color: "success.dark",
          }}
        />
      </Grid>
      <Grid item xs={2} textAlign={"center"}>
        <Iconify
          icon={"clarity:note-edit-solid"}
          sx={{
            color: "warning.main",
          }}
        />
      </Grid>
      <Grid item xs={2} textAlign={"center"}>
        <Iconify
          icon={"fluent:delete-28-filled"}
          sx={{
            color: "error.main",
          }}
        />
      </Grid>
    </Grid>
  );
};

const ScreenRoles = ({
  screen,
  screens,
  onChange,
}: {
  screen: string;
  screens: Screen[];
  onChange: (screen: string, option: string, value: boolean) => void;
}) => {
  const selectedScreen: any = screens.find((x) => x.name === screen);

  const isDefaultView =
    selectedScreen &&
    (selectedScreen["isCreate"] ||
      selectedScreen["isUpdate"] ||
      selectedScreen["isDelete"]);

  return (
    <Grid container sx={{ p: 1 }}>
      <Grid item xs={4}>
        <Typography variant="body2">{screen}</Typography>
      </Grid>
      {ROLE_OPTIONS.map((option) => (
        <Grid item xs={2} key={option} textAlign={"center"}>
          <CustomCheckbox
            disabled={option === "isView" && isDefaultView}
            checked={
              option === "isView" && isDefaultView
                ? true
                : selectedScreen
                ? selectedScreen[option] ?? false
                : false
            }
            onChange={() =>
              onChange(
                screen,
                option,
                selectedScreen ? selectedScreen[option] : false
              )
            }
          />
        </Grid>
      ))}
    </Grid>
  );
};
