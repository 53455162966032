import { Close as CloseIcon, Edit, MoreVert } from "@mui/icons-material";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Hidden,
  IconButton,
  MenuItem,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Iconify from "../../Components/Iconify";
import { CONFIRM_CHANGES, TEMPLATES } from "../../Utils/Const";
import MenuPopover from "../../Components/MenuPopover";
import SideMenu from "./SideMenu";
import shortid from "shortid";
import { COLUMN, COMPONENT, FIELDS, ROW, SIDEBAR_ITEM } from "./Const";
import {
  generateComponent,
  generateValue,
  handleMoveSidebarComponentIntoParent,
  handleMoveToDifferentParent,
  handleMoveWithinParent,
  handleRemoveItemFromLayout,
  updateColumnLayout,
} from "./Helper";
import DropZone from "./DropZone";
import Row from "./Row";
import SideBar from "./DComponents/SideBar";
import Preview from "./Preview";
import DailogBox from "../../Components/ConfirmDailog";
import _ from "lodash";
import { useCreateTemplateMutation } from "../../Api/Templates/CreateTemplate";
import Loader from "../../Components/Loader";
import { useUpdateTemplateMutation } from "../../Api/Templates/updateTemplate";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateTemplate = ({
  open,
  handleClose,
  isEdit,
  name: initialName = "",
  preview = false,
  layout: intialLayout = [],
  components: initialComponent = {},
  values: initialValue = {},
  id,
}: any) => {
  const anchorRef = useRef(null);

  const theme = useTheme();

  const { addTemplate } = useCreateTemplateMutation();

  const { updateTemplate } = useUpdateTemplateMutation();

  const [menuOpen, setmenuOpen] = useState(null);

  const [layout, setLayout] = useState<any>(intialLayout);

  const [components, setComponents] = useState<any>(initialComponent);

  const [values, setvalues] = useState<any>(initialValue);

  const [showPrompt, setshowPrompt] = useState(false);

  const [showDialog, setshowDialog] = useState(false);

  const [openDailog, setopenDailog] = useState(false);

  const [loading, setloading] = useState(false);

  const [isPreview, setisPreview] = useState(preview);

  const [name, setname] = useState(initialName);

  const handleMenuOpen = (event: any) => {
    setmenuOpen(event.currentTarget);
  };

  const handleMenuClose = () => {
    setmenuOpen(null);
  };

  useEffect(() => {
    if (!isEdit) {
    }
  }, [isEdit]);

  const updateValues = (comp: any) => {
    if (
      ![FIELDS.ALERT_FIELD, FIELDS.TYPOGRAPHY_FIELD].includes(comp.fieldType)
    ) {
      setvalues({
        ...values,
        [comp.id]: generateValue(comp.fieldType),
      });
    }
  };

  const updateFieldValues = (values: any) => {
    setvalues(values);
  };

  const handleDrop = useCallback(
    (
      dropZone: { path: string },
      item: {
        id: any;
        type: string;
        children: any;
        component: any;
        path: string;
        data: any;
      }
    ) => {
      const splitDropZonePath = dropZone.path.split("-");
      const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");
      let newItem: any = { id: item.id, type: item.type };
      if (item.type === COLUMN) {
        newItem = { ...item.data };
      }

      if (item.type === SIDEBAR_ITEM) {
        const newComponent = {
          id: shortid.generate(),
          fieldType: item.component.type,
        };
        const newItem = {
          id: newComponent.id,
          type: COMPONENT,
        };
        setComponents({
          ...components,
          [newComponent.id]: {
            ...generateComponent(newComponent),
            name: newComponent.id,
          },
        });
        updateValues(newComponent);
        setLayout(
          handleMoveSidebarComponentIntoParent(
            layout,
            splitDropZonePath,
            newItem
          )
        );
        return;
      }

      const splitItemPath = item.path.split("-");
      const pathToItem = splitItemPath.slice(0, -1).join("-");

      if (splitItemPath.length === splitDropZonePath.length) {
        if (pathToItem === pathToDropZone) {
          setLayout(
            handleMoveWithinParent(layout, splitDropZonePath, splitItemPath)
          );
          return;
        }
        setLayout(
          handleMoveToDifferentParent(
            layout,
            splitDropZonePath,
            splitItemPath,
            newItem
          )
        );
        return;
      }

      setLayout(
        handleMoveToDifferentParent(
          layout,
          splitDropZonePath,
          splitItemPath,
          newItem
        )
      );
    },
    [layout, components]
  );

  const handleDropToTrashBin = useCallback(
    (item: any) => {
      let comp: any = [];
      const splitItemPath = item.path.split("-");
      setLayout(handleRemoveItemFromLayout(layout, splitItemPath));
      if (item.type === COMPONENT) {
        comp.push(item.id);
      } else if (item.type === ROW) {
        item.children.map((col: any) => {
          col.children.map((com: any) => comp.push(com.id));
        });
      } else if (item.type === COLUMN) {
        item.children.map((com: any) => {
          comp.push(com.id);
        });
      }
      if (comp.length > 0) {
        let allComps = components;
        comp.forEach((e: string) => delete allComps[e]);
        setComponents(allComps);
        let updatedValues = values;
        comp.forEach((e: string) => delete updatedValues[e]);
        setvalues(updatedValues);
      }
    },
    [layout, components]
  );

  const copyContent = useCallback(
    (item: any) => {
      let newComponets = components;
      let newlayout = layout;
      if (item.type === ROW) {
        let { path, ...newItem } = item;
        newItem = {
          ...newItem,
          id: shortid(),
          children: newItem.children.map((col: any) => {
            return {
              ...col,
              id: shortid(),
              children: col.children.map((comp: any) => {
                let id = shortid();
                newComponets[id] = { ...components[comp.id], id: id, name: id };
                updateValues(newComponets[id]);
                return { ...comp, id: id };
              }),
            };
          }),
        };
        newlayout.splice(path, 0, newItem);
      } else if (item.type === COLUMN) {
        let newItem = item.data;
        const splitItemPath = item.path.split("-");
        newItem = {
          ...newItem,
          id: shortid(),
          children: newItem.children.map((comp: any) => {
            let id = shortid();
            newComponets[id] = { ...components[comp.id], id: id, name: id };
            updateValues(newComponets[id]);
            return { ...comp, id: id };
          }),
        };
        let rowCols = newlayout[splitItemPath[0]].children;
        rowCols.splice(splitItemPath[1], 0, newItem);
        newlayout[splitItemPath[0]] = {
          ...newlayout[splitItemPath[0]],
          children: rowCols,
        };
      } else if (item.type === COMPONENT) {
        let id = shortid();
        let newComponet = { ...components[item.id], id: id, name: id };
        let newlayout = layout;
        const path = item.path.split("-");
        let colComps = newlayout[path[0]].children[path[1]].children;
        colComps.splice(path[2], 0, { ...colComps[path[2]], id });
        newlayout[path[0]].children[path[1]].children = colComps;
        newComponets = { [id]: newComponet, ...components };
        updateValues(newComponet);
      }
      setLayout([...newlayout]);
      setComponents(newComponets);
    },
    [layout, components]
  );

  const renderRow = (row: any, currentPath: string) => {
    return (
      <Row
        key={row.id}
        data={row}
        handleDrop={handleDrop}
        components={components}
        path={currentPath}
        onCopy={copyContent}
        onDelete={handleDropToTrashBin}
      />
    );
  };

  const updateColumn = (type: any, updatedField: any, path: any) => {
    setLayout(updateColumnLayout(layout, updatedField, path));
  };

  const updateField = (field: any) => {
    setComponents({ ...components, [field.id]: field });
  };

  const onhandleClose = () => {
    if (showDialog) {
      setshowPrompt(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    setshowDialog(
      !_.isEqual(initialName, name) ||
        !_.isEqual(initialComponent, components) ||
        !_.isEqual(intialLayout, layout)
    );
  }, [components, layout, name]);

  const OnSave = () => {
    if (showDialog) {
      setopenDailog(true);
    } else {
      handleClose();
    }
  };

  const save = async () => {
    setloading(true);
    setshowDialog(false);
    await addTemplate({
      variables: {
        name,
        layout,
        components,
        values,
      },
    })
      .then(() => {
        handleClose();
      })
      .finally(() => setloading(false));
  };

  const update = async () => {
    setloading(true);
    let data: any = {};
    if (!_.isEqual(name, initialName)) {
      data["name"] = name;
    }
    if (!_.isEqual(layout, intialLayout)) {
      data["layout"] = layout;
    }
    if (!_.isEqual(components, initialComponent)) {
      data["components"] = components;
    }
    if (!_.isEqual(values, initialValue)) {
      data["values"] = values;
    }

    await updateTemplate({
      variables: {
        templateId: id,
        ...data,
      },
    })
      .then(() => {
        handleClose();
      })
      .finally(() => setloading(false));
  };

  return (
    <React.Fragment>
      <Dialog open={openDailog} onClose={() => setopenDailog(false)}>
        <Backdrop sx={{ zIndex: 1000 }} open={loading}>
          <Loader />
        </Backdrop>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            value={name}
            onChange={(event) => setname(event.target.value)}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          {!isEdit && (
            <Button
              variant="contained"
              disabled={name.trim() === ""}
              onClick={save}
            >
              Create
            </Button>
          )}
          <Button
            onClick={() => {
              setopenDailog(false);
              if (!isEdit) {
                setname("");
              }
            }}
          >
            {isEdit ? "Close" : "Cancel"}
          </Button>
        </DialogActions>
      </Dialog>
      <DailogBox
        showDialog={showPrompt}
        confirmNavigation={() => {
          handleClose();
          setshowPrompt(false);
        }}
        cancelNavigation={() => setshowPrompt(false)}
        message={CONFIRM_CHANGES}
      />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        sx={{ zIndex: 1110 }}
        PaperProps={{
          sx: {
            bgcolor: isPreview ? theme.palette.grey[200] : "white",
          },
        }}
        TransitionComponent={Transition}
      >
        <Box sx={{ display: "flex" }}>
          <AppBar
            position="fixed"
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
          >
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={onhandleClose}>
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                {isEdit || preview ? name : "Create Template"}
                {isEdit && (
                  <IconButton
                    sx={{ ml: 0.5 }}
                    size="small"
                    edge="start"
                    color="inherit"
                    onClick={() => setopenDailog(true)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                )}
              </Typography>
              {!preview && (
                <React.Fragment>
                  <Hidden smDown>
                    {/* <Button color="inherit" onClick={handleClose}>
                  Conditions
                </Button> */}
                    <Button
                      color="inherit"
                      onClick={() => setisPreview(!isPreview)}
                    >
                      {isPreview ? "Back" : "Preview"}
                    </Button>
                    {!isPreview && (
                      <Button
                        disabled={!showDialog}
                        color="inherit"
                        onClick={isEdit ? update : OnSave}
                      >
                        save
                      </Button>
                    )}
                  </Hidden>
                  <Hidden smUp>
                    <IconButton
                      ref={anchorRef}
                      color="inherit"
                      onClick={handleMenuOpen}
                    >
                      <MoreVert />
                    </IconButton>
                    <MenuPopover
                      open={Boolean(menuOpen)}
                      anchorEl={menuOpen}
                      onClose={handleMenuClose}
                      sx={{
                        p: 0,
                        mt: 1.5,
                        ml: 0.75,
                        "& .MuiMenuItem-root": {
                          typography: "body2",
                          borderRadius: 0.75,
                        },
                      }}
                    >
                      <Stack sx={{ p: 1 }}>
                        <MenuItem
                          onClick={() => {
                            setisPreview(!isPreview);
                            handleMenuClose();
                          }}
                        >
                          {!isPreview ? "Preview" : "Back"}
                        </MenuItem>
                        {!isPreview && (
                          <MenuItem onClick={isEdit ? update : OnSave}>
                            {"Save"}
                          </MenuItem>
                        )}
                      </Stack>
                    </MenuPopover>
                  </Hidden>
                </React.Fragment>
              )}
            </Toolbar>
          </AppBar>
          {!isPreview ? (
            <Box display={"flex"} width={1}>
              <SideMenu />
              <SideBar
                updateLayout={updateColumn}
                components={components}
                updateField={updateField}
                updateFieldValues={updateFieldValues}
                values={values}
              />
              <Box component="main" sx={{ flexGrow: 1, zIndex: 1, p: 3 }}>
                <Toolbar />
                <Container sx={{ border: 1, borderRadius: 0.5 }}>
                  {layout.map((row: any, index: any) => {
                    const currentPath = `${index}`;

                    return (
                      <React.Fragment key={row.id}>
                        <DropZone
                          data={{
                            path: currentPath,
                            childrenCount: layout.length,
                          }}
                          onDrop={handleDrop}
                          path={currentPath}
                        />
                        {renderRow(row, currentPath)}
                      </React.Fragment>
                    );
                  })}
                  <DropZone
                    data={{
                      path: `${layout.length}`,
                      childrenCount: layout.length,
                    }}
                    onDrop={handleDrop}
                    isLast={true}
                  />
                </Container>
              </Box>
            </Box>
          ) : (
            <Box width={1}>
              <Toolbar />
              <Preview layout={layout} components={components} />
            </Box>
          )}
        </Box>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateTemplate;
