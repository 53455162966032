import { Box } from "@mui/material";
import React from "react";
import loader from "../Assets/poorvika-loader.webp";

export const Loader = ({ width = 100, isAdjust = false, ...options }) => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      {...options}
    >
      <img width={width} src={loader} alt={"loader"} />
    </Box>
  );
};

export default Loader;
