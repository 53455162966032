import { Box, Grid } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag } from "react-dnd";
import { useAppDispatch } from "../../Store/Hooks";
import { onOpen } from "../../Store/Slices/DrawerSlice";
import Component from "./Component";
import { COLUMN } from "./Const";
import DropZone from "./DropZone";
import Options from "./Options";

const Column = ({
  data,
  components,
  handleDrop,
  path,
  isHovered,
  onDelete,
  onCopy,
}: any) => {
  const ref = useRef(null);

  const dispatch = useAppDispatch();

  const [{ isDragging }, drag] = useDrag({
    type: COLUMN,
    item: {
      type: COLUMN,
      id: data.id,
      children: data.children,
      path,
      data: data,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(ref);

  const [isHover, setisHover] = useState(false);

  const [isChildHovered, setisChildHovered] = useState(false);

  useEffect(() => {
    isHovered(isHover || isChildHovered);
  }, [isHover, isChildHovered]);

  const isColHovered = useCallback(
    (hovered: boolean) => {
      if (hovered) {
        setisHover(!hovered);
      }
      setisChildHovered(hovered);
    },
    [isHover]
  );

  const renderComponent = (component: any, currentPath: string) => {
    return (
      <Component
        key={component.id}
        data={component}
        components={components}
        path={currentPath}
        onDelete={onDelete}
        onCopy={onCopy}
        isHovered={isColHovered}
      />
    );
  };

  return (
    <Box
      ref={ref}
      onMouseOver={!isChildHovered ? () => setisHover(true) : undefined}
      onMouseOut={!isChildHovered ? () => setisHover(false) : undefined}
      sx={{
        border: 1,
        width: 1,
        height: 1,
        position: "relative",
        borderStyle: "dashed",
        borderRadius: 0.4,
      }}
    >
      {data.children.map((component: any, index: any) => {
        const currentPath = `${path}-${index}`;

        return (
          <React.Fragment key={component.id}>
            <DropZone
              data={{
                path: currentPath,
                childrenCount: data.children.length,
              }}
              onDrop={handleDrop}
            />
            {renderComponent(component, currentPath)}
            {data.children.length - 1 === index && (
              <DropZone
                data={{
                  path: `${path}-${data.children.length}`,
                  childrenCount: data.children.length,
                }}
                onDrop={handleDrop}
                isLast={true}
              />
            )}
          </React.Fragment>
        );
      })}
      {(!data.children || data.children.length === 0) && (
        <DropZone
          data={{
            path: `${path}-${data.children.length}`,
            childrenCount: data.children.length,
          }}
          onDrop={handleDrop}
          isLast={true}
        />
      )}
      <Options
        isHover={isHover}
        onDelete={() => {
          onDelete({
            id: data.id,
            type: COLUMN,
            children: data.children,
            path,
          });

          isHovered(false);
        }}
        onCopy={() => {
          onCopy({
            id: data.id,
            type: COLUMN,
            data: data,
            children: data.children,
            path,
          });

          isHovered(false);
        }}
        onEdit={() => {
          dispatch(
            onOpen({
              item: {
                type: COLUMN,
                id: data.id,
                children: data.children,
                path,
                data: data,
              },
              isOpen: true,
            })
          );
        }}
      />
    </Box>
  );
};

export default Column;
