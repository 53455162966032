import { gql, useMutation } from "@apollo/client";
//@ts-ignore
import { ALL_USERS } from "./AllUsers";

const CREATE_USER = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $role: String!
    $isActive: Boolean!
  ) {
    createUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      role: $role
      isActive: $isActive
    ) {
      id
      firstName
      lastName
      phone
      email
      isActive
      role {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`;

export function useCreateUserMutation() {
  const [addUser, { data, error, loading }] = useMutation(CREATE_USER, {
    update(cache, { data }) {
      const createdUser = data?.createUser;
      const existingUsers: any = cache.readQuery({
        query: ALL_USERS,
      });
      if (createdUser) {
        cache.writeQuery({
          query: ALL_USERS,
          data: {
            users: existingUsers
              ? [createdUser, ...existingUsers?.users]
              : [createdUser],
          },
        });
      }
    },
  });
  return { addUser, data, error, loading };
}
