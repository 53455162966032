import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  useTheme,
  FormHelperText,
} from "@mui/material";
import React from "react";

export const DCheckGroup = (props: any) => {
  const theme = useTheme();
  return (
    <FormControl>
      <FormLabel
        sx={{
          typography: "body2",
          color: `${theme.palette.grey[600]}!important`,
        }}
      >
        {props.label} {props.required && "*"}
      </FormLabel>
      <FormGroup row={props.isRow}>
        {props.options.map((option: any, index: any) => (
          <FormControlLabel
            key={index}
            value={option}
            control={
              <Checkbox
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 16,
                  },
                }}
              />
            }
            label={option}
            componentsProps={{ typography: { variant: "body2" } }}
          />
        ))}
      </FormGroup>
      <FormHelperText>{""}</FormHelperText>
    </FormControl>
  );
};
