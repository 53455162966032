import React from "react";
import Iconify from "../../Components/Iconify";

const getIcon = (name: string) => (
  <Iconify icon={name} width={22} height={22} />
);

const navConfig = [
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: getIcon("ic:twotone-speed"),
  },
  {
    title: "Users",
    path: "/users",
    icon: getIcon("uim:user-nurse"),
  },
  {
    title: "Roles",
    path: "/roles",
    icon: getIcon("eos-icons:role-binding"),
  },
  {
    title: "Templates",
    path: "/templates",
    icon: getIcon("uim:grid"),
  },
];

export default navConfig;
