import { Close, Delete } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Iconify from "../../../Components/Iconify";
import { useAppDispatch, useAppSelector } from "../../../Store/Hooks";
import { drawerReset } from "../../../Store/Slices/DrawerSlice";
import ColorBox from "../../../Utils/Components/ColorBox";
import { COLUMN, COMPONENT, FIELDS } from "../Const";
import { DEFAULT_COLUMN, generateComponent } from "../Helper";

const SideBar = ({
  updateField,
  updateLayout,
  components,
  updateFieldValues,
  values,
}: any) => {
  const theme = useTheme();

  const { isOpen, item } = useAppSelector((state) => state.drawer);

  const uploadRef: any = useRef(null);

  const disptach = useAppDispatch();

  const onClose = () => disptach(drawerReset());

  const [field, setfield] = useState<any>();

  const [menu, setmenu] = useState(0);

  const onSave = () => {
    if (item.type === COLUMN) {
      updateLayout(item.type, field, item.path);
    } else if (item.type === COMPONENT) {
      if (
        ![FIELDS.ALERT_FIELD, FIELDS.TYPOGRAPHY_FIELD].includes(field.fieldType)
      ) {
        let oldField = components[item.id];
        if (oldField.name !== field.name) {
          let { [oldField.name]: oldValue, ...other } = values;
          if ("multiple" in field) {
            oldValue = field.multiple ? [] : "";
          }
          updateFieldValues({ ...other, [field.name]: oldValue });
        }
      }
      updateField(field);
    }
    onClose();
  };

  const onReset = () => {
    if (item.type === COLUMN) {
      updateLayout(item.type, { ...field, ...DEFAULT_COLUMN }, item.path);
    } else if (item.type === COMPONENT) {
      updateField(generateComponent(field));
    }
    onClose();
  };

  const [imageError, setimageError] = useState(false);

  useEffect(() => {
    if (isOpen) {
      if (item.type === COLUMN) {
        setfield(item.data);
      } else if (item.type === COMPONENT) {
        setfield(components[item.id]);
      }
      setimageError(false);
    } else {
      setfield(undefined);
    }
  }, [isOpen]);

  const handleMenuChange = (event: React.SyntheticEvent, newValue: number) => {
    setmenu(newValue);
  };

  const updateFieldValue = (type: any, value: any) => {
    setfield({ ...field, [type]: value });
  };

  const filter = (searchText: string, key: string) => {
    return key.toLowerCase().includes(searchText.toLowerCase());
  };

  const isError =
    field &&
    item.type !== COLUMN &&
    (field.name.trim() === "" ||
      (field.name !== components[item.id].name &&
        Object.values(components)
          .filter((comp: any) => comp.id !== item.id)
          .map((comp: any) => comp.name.toLowerCase())
          .includes(field.name.toLowerCase())));

  return (
    <Drawer
      anchor={"right"}
      open={isOpen}
      sx={{ zIndex: 1110 }}
      PaperProps={{
        sx: {
          width: 300,
        },
      }}
      onClose={onClose}
    >
      <Paper
        sx={{
          width: 1,
          display: "flex",
          p: 1,
          borderRadius: 0,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="subtitle2">Properties </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close fontSize="small" />
        </IconButton>
      </Paper>
      <Box
        sx={{
          borderBottom: 1,
          width: 1,
          borderColor: "divider",
          backgroundColor: "white",
        }}
      >
        <Tabs value={menu} onChange={handleMenuChange}>
          <Tab label="Info" />
          {item.type !== COLUMN && <Tab label="Conditions" />}
        </Tabs>
      </Box>
      {menu === 0 && (
        <Box overflow={"scroll"}>
          {item.type === COLUMN && field && (
            <>
              <ViewSelector
                label={"Desktop View"}
                value={field.lg}
                onChange={(event: any) =>
                  updateFieldValue("lg", event.target.value)
                }
              />
              <ViewSelector
                label={"Laptop View"}
                value={field.md}
                onChange={(event: any) =>
                  updateFieldValue("md", event.target.value)
                }
              />
              <ViewSelector
                label={"Tablet View"}
                value={field.sm}
                onChange={(event: any) =>
                  updateFieldValue("sm", event.target.value)
                }
              />
              <ViewSelector
                label={"Mobile View"}
                value={field.xs}
                onChange={(event: any) =>
                  updateFieldValue("xs", event.target.value)
                }
              />
            </>
          )}
          {item.type !== COLUMN && field && (
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              p={2}
              width={1}
            >
              <TextField
                label={"Name"}
                required={true}
                size="small"
                fullWidth
                value={field.name}
                error={isError}
                helperText={
                  isError &&
                  (field.name.trim() !== ""
                    ? "Name should be unique"
                    : "Name is required")
                }
                onChange={(event) => {
                  if (/^[A-Za-z0-9_]*$/.test(event.target.value)) {
                    updateFieldValue("name", event.target.value);
                  }
                }}
              />
              {"label" in field && (
                <TextField
                  label={"Label"}
                  size="small"
                  multiline
                  minRows={1}
                  maxRows={5}
                  fullWidth
                  value={field.label}
                  onChange={(event) =>
                    updateFieldValue("label", event.target.value)
                  }
                />
              )}
              {"placeholder" in field && (
                <TextField
                  label={"Placeholder"}
                  size="small"
                  fullWidth
                  value={field.placeholder}
                  onChange={(event) =>
                    updateFieldValue("placeholder", event.target.value)
                  }
                />
              )}

              {"variant" in field &&
                field.fieldType === FIELDS.TYPOGRAPHY_FIELD && (
                  <FormControl fullWidth>
                    <InputLabel>Variant</InputLabel>
                    <Select
                      value={field.variant}
                      size={"small"}
                      label={"Variant"}
                      onChange={(event) =>
                        updateFieldValue("variant", event.target.value)
                      }
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root": {
                            typography: "body2",
                            borderRadius: 0.75,
                          },
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value={"h1"} sx={{ m: 1 }}>
                        Heading 1
                      </MenuItem>
                      <MenuItem value={"h2"} sx={{ m: 1 }}>
                        Heading 2
                      </MenuItem>
                      <MenuItem value={"h3"} sx={{ m: 1 }}>
                        Heading 3
                      </MenuItem>
                      <MenuItem value={"h4"} sx={{ m: 1 }}>
                        Heading 4
                      </MenuItem>
                      <MenuItem value={"h5"} sx={{ m: 1 }}>
                        Heading 5
                      </MenuItem>
                      <MenuItem value={"h6"} sx={{ m: 1 }}>
                        Heading 6
                      </MenuItem>
                      <MenuItem value={"subtitle1"} sx={{ m: 1 }}>
                        Subtitle 1
                      </MenuItem>
                      <MenuItem value={"subtitle2"} sx={{ m: 1 }}>
                        Subtitle 2
                      </MenuItem>
                      <MenuItem value={"body1"} sx={{ m: 1 }}>
                        Body 1
                      </MenuItem>
                      <MenuItem value={"body2"} sx={{ m: 1 }}>
                        Body 2
                      </MenuItem>
                      <MenuItem value={"p"} sx={{ m: 1 }}>
                        Paragraph
                      </MenuItem>
                      <MenuItem value={"caption"} sx={{ m: 1 }}>
                        Caption
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}

              {"variant" in field &&
                field.fieldType !== FIELDS.TYPOGRAPHY_FIELD && (
                  <FormControl fullWidth>
                    <InputLabel>Variant</InputLabel>
                    <Select
                      value={field.variant}
                      size={"small"}
                      label={"Variant"}
                      onChange={(event) =>
                        updateFieldValue("variant", event.target.value)
                      }
                      MenuProps={{
                        sx: {
                          "& .MuiMenuItem-root": {
                            typography: "body2",
                            borderRadius: 0.75,
                          },
                        },
                        PaperProps: {
                          sx: {
                            maxHeight: 200,
                          },
                        },
                      }}
                    >
                      <MenuItem value={"outlined"} sx={{ m: 1 }}>
                        Outlined
                      </MenuItem>
                      <MenuItem value={"filled"} sx={{ m: 1 }}>
                        Filled
                      </MenuItem>
                      <MenuItem value={"standard"} sx={{ m: 1 }}>
                        Standard
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              {"p" in field && (
                <TextField
                  label={"Padding"}
                  size="small"
                  fullWidth
                  value={field.p}
                  onChange={(event) =>
                    updateFieldValue("p", event.target.value)
                  }
                />
              )}
              {"m" in field && (
                <TextField
                  label={"Margin"}
                  size="small"
                  fullWidth
                  value={field.m}
                  onChange={(event) =>
                    updateFieldValue("m", event.target.value)
                  }
                />
              )}
              {"color" in field && (
                <ColorBox
                  label={"Color"}
                  color={field.color}
                  onColorChange={(color: any) =>
                    updateFieldValue("color", color)
                  }
                />
              )}
              {"bgcolor" in field && (
                <ColorBox
                  label={"Background"}
                  color={field.bgcolor}
                  onColorChange={(color: any) =>
                    updateFieldValue("bgcolor", color)
                  }
                />
              )}

              {"severity" in field && (
                <FormControl fullWidth>
                  <InputLabel>Severity</InputLabel>
                  <Select
                    value={field.severity}
                    size={"small"}
                    label={"Severity"}
                    onChange={(event) =>
                      updateFieldValue("severity", event.target.value)
                    }
                    MenuProps={{
                      sx: {
                        "& .MuiMenuItem-root": {
                          typography: "body2",
                          borderRadius: 0.75,
                        },
                      },
                      PaperProps: {
                        sx: {
                          maxHeight: 200,
                        },
                      },
                    }}
                  >
                    <MenuItem value={"error"} sx={{ m: 1 }}>
                      Error
                    </MenuItem>
                    <MenuItem value={"info"} sx={{ m: 1 }}>
                      Info
                    </MenuItem>
                    <MenuItem value={"success"} sx={{ m: 1 }}>
                      Success
                    </MenuItem>
                    <MenuItem value={"warning"} sx={{ m: 1 }}>
                      Warning
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {"options" in field && (
                <Autocomplete
                  multiple
                  freeSolo
                  isOptionEqualToValue={filter}
                  options={[]}
                  value={field.options}
                  onChange={(e, newval: any, reason) => {
                    updateFieldValue("options", newval);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Options"
                      placeholder="Options"
                      onKeyDown={(e: any) => {
                        if (e.code === "enter" && e.target.value) {
                          updateFieldValue("options", [
                            ...field.options,
                            e.target.value,
                          ]);
                        }
                      }}
                    />
                  )}
                />
              )}
              {"max" in field && (
                <TextField
                  label={"Maximum"}
                  size="small"
                  fullWidth
                  value={field.max}
                  type={"number"}
                  onChange={(event) =>
                    updateFieldValue("max", parseInt(event.target.value))
                  }
                />
              )}
              {"isRow" in field && (
                <FormControlLabel
                  sx={{ mt: -1.5 }}
                  control={
                    <Checkbox
                      checked={field.isRow}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("isRow", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Row"
                />
              )}
              {"required" in field && (
                <FormControlLabel
                  sx={{ mt: -1.5 }}
                  control={
                    <Checkbox
                      checked={field.required}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("required", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Required"
                />
              )}
              {"disablePast" in field && (
                <FormControlLabel
                  sx={{ mt: -1.5 }}
                  control={
                    <Checkbox
                      checked={field.disablePast}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("disablePast", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Disable Past"
                />
              )}
              {"disableFuture" in field && (
                <FormControlLabel
                  sx={{ mt: -1.5 }}
                  control={
                    <Checkbox
                      checked={field.disableFuture}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("disableFuture", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Disable Future"
                />
              )}
              {"isUpload" in field && "image" in field && (
                <FormControl>
                  <FormLabel
                    sx={{
                      typography: "body2",
                      color: `${theme.palette.grey[600]}!important`,
                    }}
                  >
                    Upload Type
                  </FormLabel>
                  <RadioGroup
                    value={field.isUpload}
                    row
                    onChange={(event) =>
                      updateFieldValue(
                        "isUpload",
                        event.target.value === "true"
                      )
                    }
                  >
                    <FormControlLabel
                      value={true}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                      componentsProps={{ typography: { variant: "body2" } }}
                      label="Local"
                    />
                    <FormControlLabel
                      value={false}
                      control={
                        <Radio
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 16,
                            },
                          }}
                        />
                      }
                      componentsProps={{ typography: { variant: "body2" } }}
                      label="API"
                    />
                  </RadioGroup>
                </FormControl>
              )}
              {"image" in field &&
                "isUpload" in field &&
                // (field.image === "" || !field.image) &&
                field.isUpload !== true && (
                  <TextField
                    label={"Image API"}
                    size="small"
                    fullWidth
                    value={field.image}
                    onChange={(event) =>
                      updateFieldValue("image", event.target.value)
                    }
                  />
                )}
              {"image" in field &&
                "isUpload" in field &&
                // (field.image === "" || !field.image) &&
                field.isUpload === true && (
                  <Box
                    className="box-center"
                    flexDirection={"column"}
                    gap={2}
                    textAlign={"center"}
                  >
                    <Box
                      border={"dashed"}
                      width={140}
                      height={140}
                      borderColor={"divider"}
                      borderRadius={2}
                    >
                      <IconButton
                        sx={{ width: 1, height: 1 }}
                        disableRipple
                        onClick={() => uploadRef.current.click()}
                      >
                        <input
                          type="file"
                          ref={uploadRef}
                          accept={"image/*"}
                          style={{ display: "none" }}
                          name={"photoUpload"}
                          onChange={(event) => {
                            if (event.target.files) {
                              const fileSize =
                                event.target.files[0].size / 1024 / 1024;
                              if (fileSize <= 5) {
                              } else {
                                setimageError(true);
                              }
                            }
                          }}
                        />
                        <Box
                          width={1}
                          height={1}
                          className={"box-center"}
                          position={"relative"}
                          borderRadius={2}
                        >
                          {field.image && field.image !== "" && (
                            <Box
                              width={1}
                              height={1}
                              className={"box-center"}
                              component={"img"}
                              sx={{}}
                              src={field.image ?? undefined}
                              borderRadius={2}
                            />
                          )}
                          <Box
                            width={1}
                            height={1}
                            position={"absolute"}
                            bgcolor={
                              !(field.image && field.image !== "")
                                ? "grey.200"
                                : "transparent"
                            }
                            sx={{
                              "& div": {
                                display: !(field.image && field.image !== "")
                                  ? "flex"
                                  : "none",
                              },
                              "&:hover": {
                                "& div": { display: "flex" },
                                bgcolor:
                                  field.image && field.image !== ""
                                    ? "black"
                                    : "grey.100",
                                opacity: !(field.image && field.image !== "")
                                  ? 1
                                  : 0.7,
                                color: !(field.image && field.image !== "")
                                  ? "gray"
                                  : "grey.100",
                              },
                            }}
                            className={"box-center"}
                          >
                            <Box
                              display={"flex"}
                              component={"div"}
                              flexDirection={"column"}
                              gap={0.5}
                              alignItems={"center"}
                            >
                              <Iconify
                                sx={{ width: 24 }}
                                icon={"mdi:camera-plus"}
                              />
                              <Typography variant="caption">
                                Upload photo
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </IconButton>
                    </Box>
                    <Typography variant="caption" color={"grey.600"}>
                      {" "}
                      Allowed *.jpeg, *.jpg, *.png, *.gif, *.webp <br /> max
                      size of 5.0 MB
                      <br />
                      {field.image && field.image !== "" && (
                        <IconButton
                          color="error"
                          disableRipple
                          onClick={
                            () => {}
                            // setFieldValue(
                            //   values.photoUpload ? "photoUpload" : "photo",
                            //   null
                            // )
                          }
                        >
                          <Delete sx={{ fontSize: 14 }} />
                          <Typography sx={{ ml: 0.5 }} variant="caption">
                            Remove photo
                          </Typography>
                        </IconButton>
                      )}
                    </Typography>
                  </Box>
                )}
              {"width" in field && (
                <TextField
                  label={"Width"}
                  size="small"
                  fullWidth
                  value={field.width}
                  onChange={(event) =>
                    updateFieldValue("width", event.target.value)
                  }
                />
              )}
              {"height" in field && (
                <TextField
                  label={"Height"}
                  size="small"
                  fullWidth
                  value={field.height}
                  onChange={(event) =>
                    updateFieldValue("height", event.target.value)
                  }
                />
              )}
              {"multiple" in field && (
                <FormControlLabel
                  sx={{ mt: -1.5 }}
                  control={
                    <Checkbox
                      checked={field.multiple}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("multiple", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Multiple"
                />
              )}

              {"multiline" in field && (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.multiline}
                      size={"small"}
                      onChange={(event) =>
                        updateFieldValue("multiline", event.target.checked)
                      }
                    />
                  }
                  componentsProps={{ typography: { variant: "body2" } }}
                  label="Multiline"
                />
              )}
              {"minRows" in field &&
                "multiline" in field &&
                field.multiline && (
                  <TextField
                    label={"Minimum Rows"}
                    size="small"
                    fullWidth
                    value={field.minRows}
                    type={"number"}
                    onChange={(event) =>
                      updateFieldValue("minRows", event.target.value)
                    }
                  />
                )}

              {"maxRows" in field &&
                "multiline" in field &&
                field.multiline && (
                  <TextField
                    label={"maximum Rows"}
                    size="small"
                    fullWidth
                    value={field.maxRows}
                    type={"number"}
                    onChange={(event) =>
                      updateFieldValue("maxRows", event.target.value)
                    }
                  />
                )}
            </Box>
          )}
        </Box>
      )}
      <Divider />
      <Box p={1} display={"flex"} gap={1} justifyContent={"end"}>
        <Button
          size="small"
          disabled={isError}
          variant="outlined"
          onClick={onReset}
        >
          Reset
        </Button>
        <Button
          size="small"
          disabled={isError}
          variant="contained"
          onClick={onSave}
        >
          Save
        </Button>
      </Box>
    </Drawer>
  );
};

const ViewSelector = ({ label, value, onChange }: any) => {
  return (
    <Box
      p={1}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Typography variant="body2">{label}</Typography>
      <Select
        value={value.toString()}
        sx={{ width: 190 }}
        size={"small"}
        onChange={onChange}
        MenuProps={{
          sx: {
            ml: 0.75,
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem value={12} sx={{ m: 1 }}>
          Full
        </MenuItem>
        <MenuItem value={9} sx={{ m: 1 }}>
          Three Fourth
        </MenuItem>
        <MenuItem value={8} sx={{ m: 1 }}>
          Two Third
        </MenuItem>
        <MenuItem value={6} sx={{ m: 1 }}>
          Half
        </MenuItem>
        <MenuItem value={4} sx={{ m: 1 }}>
          One Third
        </MenuItem>
        <MenuItem value={3} sx={{ m: 1 }}>
          Quater
        </MenuItem>
      </Select>
    </Box>
  );
};

export default SideBar;
