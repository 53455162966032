import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Button, Typography, Container } from "@mui/material";
import { PAGE_NOT_FOUND, RETURN_HOME } from "../../Utils/Const";

const ContentStyle = styled("div")(({ theme }) => ({
  height: 1,
  margin: "auto",
  minHeight: 500,
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

const NotFound = () => {
  return (
    <Container sx={{ height: 1 }}>
      <ContentStyle sx={{ textAlign: "center", alignItems: "center" }}>
        <Typography variant="h3" paragraph>
          {PAGE_NOT_FOUND}
        </Typography>
        <Button to="/" size="large" variant="contained" component={RouterLink}>
          {RETURN_HOME}
        </Button>
      </ContentStyle>
    </Container>
  );
};

export default NotFound;
