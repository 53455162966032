import { gql, useMutation } from "@apollo/client";

const UPDATE_ROLE = gql`
  mutation UpdateRole(
    $roleId: String!
    $isActive: Boolean
    $screens: [screenInput]
  ) {
    updateRole(roleId: $roleId, isActive: $isActive, screens: $screens) {
      id
      name
      isActive
      count
      createdAt
      updatedAt
      screens {
        name
        isCreate
        isUpdate
        isDelete
        isView
      }
    }
  }
`;

export function useUpdateRoleMutation() {
  const [updateRole, { data, error, loading }] = useMutation(UPDATE_ROLE);
  return { updateRole, data, error, loading };
}
