import { TextField } from "@mui/material";
import React from "react";

const DTextField = (props: any) => {
  return (
    <TextField
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      size="small"
      {...props}
    />
  );
};

export default DTextField;
