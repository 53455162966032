import { gql, useMutation } from "@apollo/client";
import { ALL_TEMPLATES } from "./AllTemplates";

const DELETE_TEMPLATE = gql`
  mutation Mutation($templateId: String!) {
    deleteTemplate(templateId: $templateId) {
      id
      name
    }
  }
`;

export function useDeleteTemplateMutation() {
  const [deleteTemplate, { data, error, loading, reset }] = useMutation(
    DELETE_TEMPLATE,
    {
      update(cache, { data }) {
        const deletedId = data?.deleteTemplate.id;
        const existingTemplates: any = cache.readQuery({
          query: ALL_TEMPLATES,
        });

        if (deletedId && existingTemplates) {
          cache.writeQuery({
            query: ALL_TEMPLATES,
            data: {
              templates: existingTemplates?.templates.filter(
                (x: { id: any }) => x.id !== deletedId
              ),
            },
          });
        }
      },
    }
  );
  return { deleteTemplate, data, error, loading, reset };
}
