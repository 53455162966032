import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import React from "react";

const DMenuItem = (props: any) => {
  return (
    <FormControl sx={{ width: 1 }}>
      <InputLabel shrink={true}>
        {props.label} {props.required && "*"}
      </InputLabel>
      <Select
        value={props.multiple ? [] : ""}
        label={props.label}
        notched={true}
        size={"small"}
        fullWidth
        required={props.required}
        multiple={props.multiple}
        placeholder={props.placeholder}
        MenuProps={{
          sx: {
            "& .MuiMenuItem-root": {
              typography: "body2",
              borderRadius: 0.75,
            },
          },
          PaperProps: {
            sx: {
              maxHeight: 200,
            },
          },
        }}
      >
        {props.options.map((option: any, index: any) => (
          <MenuItem key={`${option}-${index}`} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{""}</FormHelperText>
    </FormControl>
  );
};

export default DMenuItem;
