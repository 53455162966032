import { styled } from "@mui/material/styles";
import {
  Toolbar,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem,
} from "@mui/material";
import React from "react";
import useResponsive from "../../Hooks/UseResponsive";
import Iconify from "../../Components/Iconify";
import { useRoleListQuery } from "../../Api/Roles/RolesList";
import { Role } from "../../Store/Slices/UserSlice";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }: any) => ({
  // height: 96,
  display: "flex",
}));

export default function UserListToolbar({
  selectedRole,
  onRoleChange,
  filterName,
  onFilterName,
  isRole = true,
  placeholder = "Search user...",
}: any) {
  const isResponsive = useResponsive("up", "md");

  const { loading, roles } = useRoleListQuery();

  return (
    <RootStyle
      sx={{ gap: 1, flexDirection: { xs: "column", md: "row" }, py: 2 }}
    >
      {isRole && (
        <TextField
          select
          label="Role"
          value={selectedRole}
          onChange={(event) => onRoleChange(event.target.value)}
          SelectProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  p: 1,
                  "& .MuiMenuItem-root": {
                    typography: "body2",
                    borderRadius: 0.75,
                    marginY: 0.5,
                  },
                },
              },
            },
          }}
          sx={{
            width: { md: 240, xs: 1 },
          }}
        >
          <MenuItem key={"ALL"} value={"ALL"}>
            {"ALL"}
          </MenuItem>
          {!loading &&
            roles.map((option: Role) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
        </TextField>
      )}
      <OutlinedInput
        value={filterName}
        fullWidth={isResponsive ? isRole : true}
        onChange={onFilterName}
        placeholder={placeholder}
        startAdornment={
          <InputAdornment position="start">
            <Iconify
              icon="eva:search-fill"
              sx={{ color: "text.disabled", width: 20, height: 20 }}
            />
          </InputAdornment>
        }
      />
    </RootStyle>
  );
}
