import { gql, useQuery } from "@apollo/client";

export const ROLES_LIST = gql`
  query Roles {
    roles {
      id
      name
      isActive
    }
  }
`;

export function useRoleListQuery() {
  const { data, error, loading } = useQuery(ROLES_LIST);
  const { roles } = data || { roles: [] };

  return { roles, error, loading };
}
