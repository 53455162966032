import React from "react";
import { Navigate } from "react-router-dom";

interface PrivateRoute {
  allowVisit: Promise<boolean> | boolean;
  redirectTo: string;
  component: React.ElementType;
}

const PrivateRouter = ({
  allowVisit,
  redirectTo,
  component: Component,
}: PrivateRoute) => {
  return !allowVisit ? (
    <Navigate to={redirectTo} replace={true} />
  ) : (
    <Component />
  );
};

export default PrivateRouter;
