import { ExpandMore } from "@mui/icons-material";
import {
  Drawer,
  alpha,
  Toolbar,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Grid,
  Card,
  Box,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDrag } from "react-dnd";
import Iconify from "../../Components/Iconify";
import { SIDEBAR_ITEM, SIDEBAR_ITEMS } from "./Const";

const SideMenu = () => {
  const theme = useTheme();

  const [isCompExpanded, setisCompExpanded] = useState(true);

  return (
    <Drawer
      variant="permanent"
      sx={{ width: { xs: 100, sm: 200, md: 300 } }}
      PaperProps={{
        sx: {
          overflowY: "unset",
          width: { xs: 100, sm: 200, md: 300 },
          backdropFilter: "blur(6px)",
          WebkitBackdropFilter: "blur(6px)",
          backgroundColor: alpha(theme.palette.background.default, 0.8),
          borderRightStyle: "dashed",
        },
      }}
      open
    >
      <Toolbar />
      <Accordion
        disableGutters={true}
        expanded={isCompExpanded}
        onChange={() => setisCompExpanded(!isCompExpanded)}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Typography variant="subtitle2">Components</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container p={1} spacing={1}>
            {SIDEBAR_ITEMS.map((option, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <SideMenuOptions
                  option={option}
                  index={index}
                  palette={theme.palette.primary.main}
                />
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Drawer>
  );
};

export default SideMenu;

export const SideMenuOptions = ({ option, palette, index }: any) => {
  const [{}, drag] = useDrag(() => ({
    type: SIDEBAR_ITEM,
    item: option,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  return (
    <div>
      <Card
        ref={drag}
        sx={{
          borderRadius: 0.5,
          zIndex: 100,
          "&:hover": {
            color: palette,
            cursor: "pointer",
            transition: "all",
          },
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
          p={1}
        >
          <Iconify
            icon={option.component.icon}
            sx={{ width: 24, height: 24 }}
          />
          <Typography variant="caption" fontSize={8}>
            {option.component.label}
          </Typography>
        </Box>
      </Card>
    </div>
  );
};
