import {
  Box,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  styled,
  tableCellClasses,
} from "@mui/material";
import React from "react";

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

export default function TableHeader({
  order,
  orderBy,
  headLabel,
  onRequestSort,
}: any) {
  const createSortHandler = (property: any) => (event: any) => {
    onRequestSort(event, property);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      //@ts-ignore
      backgroundColor: theme.palette.background["neutral"],
      "&:first-of-type": {
        borderTopLeftRadius: theme.spacing(1),
        borderBottomLeftRadius: theme.spacing(1),
        borderBottom: "none",
      },
      "&:last-of-type": {
        borderTopRightRadius: theme.spacing(1),
        borderBottomRightRadius: theme.spacing(1),
        borderBottom: "none",
      },
    },
  }));

  return (
    <TableHead>
      <TableRow>
        {headLabel.map((headCell: any) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.alignRight ? "right" : "left"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon
              sx={{
                color: "action.active",
                "&:hover": { color: "primary.main" },
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box sx={{ ...visuallyHidden }}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
