import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  InputAdornment,
  OutlinedInput,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import BreadCrums from "../../Components/Breadcrum";
import useRole from "../../Hooks/UseRole";
import { ENABLED, TEMPLATES } from "../../Utils/Const";
import CreateTemplate from "./Create";
import { DndProvider } from "react-dnd";
import { isMobile } from "react-device-detect";
import { TouchBackend } from "react-dnd-touch-backend";
import { HTML5Backend } from "react-dnd-html5-backend";
import Iconify from "../../Components/Iconify";
import { StyledTabs } from "../Users";
import Scrollbar from "../../Components/Scrollbar";
import TableHeader from "../../Components/TableHeader";
import { RootStyle } from "../../Containers/MainLayout";
import { useTemplatesQuery } from "../../Api/Templates/AllTemplates";
import _ from "lodash";
import Loader from "../../Components/Loader";
import NoData from "../../Components/NoData";
import UseMoreMenu from "../../Components/MoreButton";
import DailogBox from "../../Components/ConfirmDailog";
import AlertBar from "../../Components/SnackBar";
import { isloading } from "../../Store/Slices/UserSlice";
import { useAppDispatch } from "../../Store/Hooks";
import { useDeleteTemplateMutation } from "../../Api/Templates/DeleteTemplate";

const TABLE_HEAD = [
  { id: "name", label: "Name" },
  { id: "isApproved", label: "Approval", alignRight: true },
  { id: "isActive", label: "Status", alignRight: true },
  { id: "" },
];

const Template = () => {
  const dispatch = useAppDispatch();

  const { myRole } = useRole(TEMPLATES.TEMPLATES);

  const [open, setOpen] = React.useState(false);

  const [status, setstatus] = useState(0);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [name, setname] = useState("");

  const [page, setPage] = useState(0);

  const [isEdit, setisEdit] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [selected, setselected] = useState<any | null>(null);

  const [showError, setshowError] = useState(false);

  const [openDeleteDailog, setopenDeleteDailog] = useState(false);

  const [preview, setpreview] = useState(false);

  const { templates, loading } = useTemplatesQuery();

  const { deleteTemplate } = useDeleteTemplateMutation();

  const onFilterName = (e: any) => setname(e.target.value);

  const handleRequestSort = (
    event: any,
    property: React.SetStateAction<string>
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClose = () => {
    setisEdit(false);
    setselected(null);
    setpreview(false);
    setOpen(false);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setstatus(newValue);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const filteredTemplates = templates
    ? templates.filter(
        (x: any) =>
          (status === 2 ? true : status === 1 ? !x.isActive : x.isActive) &&
          (name !== ""
            ? x.name.toLowerCase().indexOf(name.toLowerCase()) !== -1
            : true)
      )
    : [];

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDeleteDailogOpen = (template: any) => {
    setselected(template);
    setopenDeleteDailog(true);
  };

  const onDeleteDailogClose = () => {
    setselected(null);
    setopenDeleteDailog(false);
  };

  const onDeleteTemplate = async () => {
    const id = selected?.id;
    onDeleteDailogClose();
    dispatch(isloading(true));
    await deleteTemplate({
      variables: {
        templateId: id,
      },
    })
      .catch((err) => setshowError(true))
      .finally(() => dispatch(isloading(false)));
  };

  return (
    <Container>
      <DailogBox
        showDialog={openDeleteDailog}
        cancelNavigation={onDeleteDailogClose}
        confirmNavigation={onDeleteTemplate}
        message={TEMPLATES.CONFIRM_DELETE}
      />
      <AlertBar
        open={showError}
        handleClose={() => setshowError(false)}
        message={TEMPLATES.DELETE_FAILED}
      />
      {open && (
        <DndProvider
          backend={isMobile ? TouchBackend : HTML5Backend}
          options={{ enableMouseEvents: true }}
        >
          <CreateTemplate
            open={open}
            handleClose={handleClose}
            isEdit={isEdit}
            preview={preview}
            {...((preview || isEdit) && selected)}
          />
        </DndProvider>
      )}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Typography variant="h4">{TEMPLATES.TEMPLATES}</Typography>

        {myRole?.isCreate && (
          <Button
            variant="contained"
            onClick={() => setOpen(true)}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            {TEMPLATES.NEW_TEMPLATE}
          </Button>
        )}
      </Stack>
      <BreadCrums links={[{ name: TEMPLATES.TEMPLATES, href: "" }]} />
      <Card sx={{ my: 2, border: 1, borderColor: "divider" }}>
        <Box
          sx={{
            borderBottom: 1,
            px: 2,
            borderColor: "divider",
            bgcolor: "background.neutral",
          }}
        >
          <StyledTabs value={status} onChange={handleChange}>
            <Tab disableRipple label={ENABLED.ACTIVE} />
            <Tab disableRipple label={ENABLED.DISABLED} />
            <Tab disableRipple label="All" />
          </StyledTabs>
        </Box>

        <RootStyle
          sx={{ gap: 1, flexDirection: { xs: "column", md: "row" }, p: 2 }}
        >
          <OutlinedInput
            value={name}
            fullWidth
            onChange={onFilterName}
            placeholder={"Template"}
            startAdornment={
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: "text.disabled", width: 20, height: 20 }}
                />
              </InputAdornment>
            }
          />
        </RootStyle>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800, px: 2 }}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  padding: 2,
                  borderBottom: "none",
                },
              }}
            >
              <TableHeader
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {!_.isEmpty(filteredTemplates) &&
                  filteredTemplates
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, id: number) => (
                      <TableRow sx={{ borderBottom: 0 }} key={id} tabIndex={-1}>
                        <TableCell align="left">{row.name}</TableCell>
                        <TableCell align="right" width={1}>
                          <Box display={"flex"} alignItems={"center"} width={1}>
                            <Iconify
                              icon={
                                !row.isApproved
                                  ? "mdi:camera-timer"
                                  : "teenyicons:tick-circle-solid"
                              }
                              sx={{
                                color: row.isApproved
                                  ? "success.dark"
                                  : "warning.main",
                                width: 16,
                                height: 16,
                              }}
                            />
                            <Typography
                              p={0.5}
                              borderRadius={1}
                              color={
                                row.isApproved ? "success.dark" : "warning.main"
                              }
                            >
                              {row.isApproved ? "Approved" : "Draft"}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell align="right">
                          <Typography
                            p={0.5}
                            borderRadius={1}
                            variant="caption"
                            color={"white"}
                            bgcolor={
                              row.isActive ? "success.dark" : "error.main"
                            }
                          >
                            {row.isActive ? ENABLED.ACTIVE : ENABLED.DISABLED}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <UseMoreMenu
                            isDelete={myRole?.isDelete}
                            isEdit={myRole?.isUpdate}
                            isOther={true}
                            otherLabel={"Preview"}
                            onEdit={() => {
                              setselected(row);
                              setisEdit(true);
                              setOpen(true);
                            }}
                            otherIcon={"mdi:view-dashboard-outline"}
                            onDelete={() => onDeleteDailogOpen(row)}
                            onOther={() => {
                              setselected(row);
                              setpreview(true);
                              setOpen(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                {_.isEmpty(filteredTemplates) && (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={TABLE_HEAD.length}>
                      {loading ? <Loader /> : <NoData />}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={filteredTemplates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
};

export default Template;
