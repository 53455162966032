import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import { useAuth } from "../Apollo/Auth";
import Loader from "../Components/Loader";
import DashboardLayout from "../Containers/MainLayout";
import useRole from "../Hooks/UseRole";
import Dashboard from "../Pages/Dashboard/index";
import NotFound from "../Pages/NotFound/index";
import Roles from "../Pages/Roles/index";
import Template from "../Pages/Templates";
import Users from "../Pages/Users/index";
import { useAppSelector } from "../Store/Hooks";

const NavRoutes = [
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" />,
      },
      { path: "dashboard", element: <Dashboard /> },
      {
        path: "roles",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Roles />,
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      {
        path: "users",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Users />,
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      {
        path: "templates",
        element: <Outlet />,
        children: [
          {
            path: "",
            element: <Template />,
          },
          { path: "*", element: <Navigate to="/404" replace /> },
        ],
      },
      { path: "404", element: <NotFound /> },
      { path: "*", element: <Navigate to="/404" replace /> },
    ],
  },
];

const MainPage = () => {
  const me = useAppSelector((state) => state.user.me);

  const { me: fetchme } = useAuth();

  useEffect(() => {
    fetchme();
  }, []);

  return <React.Fragment>{!me ? <Loader /> : <Router />}</React.Fragment>;
};

export const Router = () => {
  const me = useAppSelector((state) => state.user.me);
  const [routes, setroutes] = useState<any[]>([]);

  const { permittedScreens } = useRole("");

  const filterArray = (paths: any[]) => {
    let path_result: any = [];
    paths.forEach((path) => {
      let temp_path = path;
      let children = path["children"];
      if (children) {
        temp_path["children"] = filterArray(children);
      }
      if (permittedScreens.includes(temp_path.path.replace("/*", ""))) {
        path_result.push(temp_path);
      }
    });
    return path_result;
  };

  useEffect(() => {
    setroutes(filterArray(NavRoutes));
  }, []);

  return useRoutes(routes);
};

export default MainPage;
