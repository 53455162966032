import React from "react";
import NoImage from "../../../Assets/Image_not_available.png";

const DImage = (props: any) => {
  const { image, id, height, width } = props;
  return (
    <img
      height={height}
      width={width}
      src={image && image !== "" ? image : NoImage}
      alt={id}
    />
  );
};

export default DImage;
