import { gql, useMutation } from "@apollo/client";

export const UPDATE_USER = gql(`
mutation UpdateUser($userId: String!, $firstName: String, $lastName: String, $role: String, $isActive: Boolean) {
  updateUser(userId: $userId, firstName: $firstName, lastName: $lastName, role: $role, isActive: $isActive) {    id
      firstName
      lastName
      phone
      email
      isActive
      role {
        id
        name
      }
      createdAt
      updatedAt
  }
}`);
export function useUpdateUserMutation() {
  const [updateUser, { data, error, loading }] = useMutation(UPDATE_USER);
  return { updateUser, data, error, loading };
}
