import { Box, Typography, useTheme } from "@mui/material";
import React, { useRef, useState } from "react";
import { ChromePicker } from "react-color";
import hexToRgba from "hex-to-rgba";
import rgbHex from "rgb-hex";
import { useOutsideClick } from "../../Hooks/useOutsideClick";

const ColorBox = ({ label, color, onColorChange }: any) => {
  const theme = useTheme();
  const { wrapperRef, isVisible, setIsVisible } = useOutsideClick(false);
  return (
    <Box>
      <Box
        component={"fieldset"}
        border={1}
        width={1}
        height={54}
        borderRadius={1}
        pb={0.5}
        px={0.5}
        borderColor={theme.palette.divider}
      >
        <Typography
          variant="caption"
          sx={{ ml: 0.5, px: 0.5 }}
          color={theme.palette.grey[600]}
          component={"legend"}
        >
          {label}
        </Typography>
        <Box
          bgcolor={color}
          sx={{ cursor: "pointer" }}
          ref={wrapperRef}
          borderRadius={0.5}
          width={1}
          height={1}
          onClick={isVisible ? undefined : () => setIsVisible(true)}
        >
          {isVisible && (
            <Box position={"absolute"} mt={4} zIndex={5}>
              <ChromePicker
                styles={{
                  default: {
                    picker: {
                      width: 255,
                    },
                  },
                }}
                color={hexToRgba(color)}
                onChange={(c) => {
                  onColorChange(
                    "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)
                  );
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ColorBox;
